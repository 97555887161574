import React, { useState, useEffect, useRef } from "react";
import "../../css/bootstrap.min.css";
import "../../css/fontawesome.css";
import "../../css/flaticon.css";
import "../../css/pbminfotech-base-icons.css";
import "../../css/swiper.min.css";
import "../../css/magnific-popup.css";
import "../../css/shortcode.css";
import "../../css/demo-3.css";
import "../../css/base.css";
import "../../css/style.css";
import "../../css/responsive.css";
import HomeSlider from "./HomeSlider/HomeSlider";
import HomeAboutUs from "./HomeAboutUs/HomeAboutUs";
import SuccessNumbers from "./Individual/SuccessNumbers";
import HomeNotices from "./HomeNotices/HomeNotices.js";
import NewSuccessStories from "./SuccessStories/NewSuccessStories/NewSuccessStories.js";
import Choose from "./Choose/Choose.js";
import Clients from "./Clients/Clients.js";
import "./Home.css";
import HomeCounter from "./HomeCounter/HomeCounter";
import { useDispatch, useSelector } from "react-redux";
import { Updates } from "./store/UpdateDataSlice.js";
import { Helmet } from "react-helmet";
import Home3ServicesNew from "./HomeServicesNew/Home3ServicesNew.js";
import { PopupData } from "./store/PopupDataSlice.js";
import { BASE_URL_Image } from "../../constants.js";

var imagepopup = "";
var titlepopup = "";
var despopup = "";
var linkpopup = "";
const Popup = ({ onClose }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    // Handle clicks outside of the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]);
  return (
    <div className="popupoverlay">
      <div className="div-card-container" ref={popupRef}>
        <button onClick={onClose} className="close-button">
          &times;
        </button>

        {/* Image Section */}
        <div className="Section1">
          <img
            src={imagepopup} // Replace with your image URL
            alt="Popup"
            className="popupimage"
          />
        </div>

        {/* Text Section */}
        <div className="Section2">
          <h4>{titlepopup}</h4>
          <td dangerouslySetInnerHTML={{ __html: despopup }} />
          <button onClick={() => window.open(linkpopup)}>View Detail</button>
        </div>
      </div>
    </div>
  );
};

function Homepage() {
  const [showPopup, setShowPopup] = useState(false);
  const topWrapperRef1 = useRef(null);
  useEffect(() => {
    dispatch(PopupData()).then((res) => {
      if (res.payload.data[0].status == "1") {
        imagepopup = BASE_URL_Image + res.payload.data[0].image;
        titlepopup = res.payload.data[0].title;
        despopup = res.payload.data[0].description;
        linkpopup = res.payload.data[0].link;
        const timer = setTimeout(() => {
          setShowPopup(true);
        }, 5000);
        return () => clearTimeout(timer);
      }
    });
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  const instagramPostUrl = "https://www.instagram.com/p/CGXASWKjlMa/"; // Replace with your Instagram post URL

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Updates());
    dispatch(PopupData());
  }, []);
  const updates = useSelector((state) => state.updatesdata);
  const popupData = useSelector((state) => state.popupData);

  console.log("popupData");
  console.log(popupData);

  const updatesData = updates?.data?.data;

  return (
    <>
      {showPopup && <Popup onClose={closePopup} />}
      <Helmet>
        <title>
          Renewable energy and Petroleum management &amp; research institute in
          Gujarat.
        </title>
        <meta
          name="description"
          content="GERMI offers comprehensive services including expert Advisory &amp; Consulting, cutting-
        edge research for renewable energy and petroleum energy in Gujarat."
        />

        <meta
          name="keywords"
          content="Petroleum Energy Research &amp; Management Institute, Renewable energy
management &amp; research institute in Gujarat, energy security, energy management, India,
energy transition, renewable energy, energy policy, Energy consultancy Gujarat, sustainable
energy"
        />
      </Helmet>

      {/* <PropagateLoader
                color={"#7cba27"}
                loading={loading}
                size={30}
                className='loader-center'
                aria-label="Loading Spinner"
                data-testid="loader"
                style={loading ? {
                    display: "flex"
                } : { display: "none" }}
            />*/}
      <div>
        <div className="page-wrapper pbmit-bg-color-white">
          <div className="page-content">
            {/* Home Slider Start */}

            <HomeSlider data={updatesData} topWrapperRef1={topWrapperRef1} />

            {/* Home Slider End */}

            {/* About Us Start */}
            <section>
              <HomeAboutUs />
            </section>
            {/* About Us End */}

            {/* Success Number Start */}
            {/* <section>
                        <SuccessNumbers />
                    </section> */}

            <section>
              <HomeCounter />
            </section>
            {/* Success Number End */}

            {/* Success Stories End */}

            {/* <section >
                            <HomeServicesNew />
                        </section> */}
            <section>
              <Home3ServicesNew ref={topWrapperRef1} />
            </section>

            {/* Success Stories Start */}
            <section className="ns-section">
              <NewSuccessStories />
            </section>
            {/* Services End */}

            {/* Notice Card Start */}
            <section>
              <HomeNotices />
            </section>

            {/* Notice Card End */}

            {/* Choose Start */}
            <section>
              <Choose />
            </section>
            {/* Choose End */}

            <section class="feature-four">
              <div class="container g-0">
                <div class="row g-0">
                  <div class="col-xxl-6 col-xl-5">
                    <div class="feature-four_bg_video">
                      <iframe
                        src="https://www.youtube.com/embed/xePvrCA9L_Y?si=UPmW2OVkE2UppgY5"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-5">
                    <div class="feature-four_single">
                      <div class="text-white">

                        <h2 class="pbmit-title22">
                        Zero CO2 Emission Mechanism for the Development of Biogas using Bio-Agri Wastes
                        </h2>
                        <div class="pbmit-heading-desc" style={{ textAlign: 'justify' }}>
                        The biogas plant, with a 25 cubic meter/day capacity, processes 500 kg of bio-waste daily using a floating dome model. Waste is crushed in a 500 kg/day food crusher and mixed with water (1:1) in a slurry preparation tank. After homogenization, the slurry enters a 50 cubic meter digester under oxygen-deficient conditions.
Biogas is purified using a rotating disc-based system to remove moisture and CO2, followed by an H2S scrubber. The purified gas is stored in a 10 cubic meter balloon and compressed into a 1 cubic meter pressurized vessel. It powers a 5 kW biogas generator, supporting plant operations.
Scrubbed CO2 water is reused in two 10,000-liter microalgal tanks, where algae sequester CO2, achieving zero emissions.
                        </div>
                    
                     
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <section
    className="elementor-section elementor-top-section elementor-element elementor-element-107d761 cspt-col-stretched-left elementor-section-stretched cspt-bg-color-over-image elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="107d761"
    data-element_type="section"
    data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
  >
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-d7350c9 cspt-bg-color-over-image"
        data-id="d7350c9"
        data-element_type="column"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-89cbe29 elementor-widget elementor-widget-spacer"
            data-id="89cbe29"
            data-element_type="widget"
            data-widget_type="spacer.default"
          >
            <div className="elementor-widget-container">
              <div className="elementor-spacer">
                <div className="elementor-spacer-inner" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-d954510 cspt-bg-color-yes cspt-elementor-bg-color-globalcolor cspt-text-color-white cspt-bg-color-over-image"
        data-id="d954510"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-25dfc0e cspt-align-left elementor-widget elementor-widget-cspt_heading"
            data-id="25dfc0e"
            data-element_type="widget"
            data-widget_type="cspt_heading.default"
          >
            <div className="elementor-widget-container">
              <div className="cspt-heading-subheading left-align cspt-reverse-heading-yes">
                <h4 className="cspt-element-subtitle">OUR STORY</h4>
                <h2 className="cspt-element-title">
                  Best Solution for Your Home Service
                </h2>
                <div className="cspt-heading-desc">
                  Industrial engineering is a branch of engineering which deals
                  with the optimization of complex processes, systems, or
                  organizations. Industrial are integrated to give a solutions
                  for project scheduling.
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-a1fbf6b cspt-btn-color-white cspt-btn-shape-round cspt-btn-style-flat elementor-widget elementor-widget-button"
            data-id="a1fbf6b"
            data-element_type="widget"
            data-widget_type="button.default"
          >
            <div className="elementor-widget-container">
              <div className="elementor-button-wrapper">
                <a
                  className="elementor-button elementor-button-link elementor-size-md"
                  href="https://greenly-demo.pbminfotech.com/elementor-site4/about-us-1/"
                >
                  <span className="elementor-button-content-wrapper">
                    <span className="elementor-button-text">Learn More</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}

            {/* <section className='youtube-video-link-section'>
           
                <div className='video-container'>
                    <iframe
                        src="https://www.youtube.com/embed/x2_Q_wrH270?si=mj4rmbgak0hOVRuX"
                        title="YouTube video player"
                        frameborder="1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                        className='youtube-video-2'>
                    </iframe>
                   <iframe
                        src="https://www.youtube.com/embed/yAJIxmTncPc?si=J11OMLK6Gr1pBpgq"
                        title="YouTube video player"
                        frameborder="1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                        className='youtube-video-2'>
                    </iframe>
                </div>
            </section> */}

            {/* Social Media Start */}
            {/* <SocialMedia /> */}
            {/*Social Media End */}

            {/* Clients Start */}
            {/* <section className='clients-bg-div'>
                            <div className="container">
                                <div className=" text-center">
                                    <div className='pt-5'>
                                    <OnScrollAnimation title1="oc1" title2="oc2" hr1="oc3" hr2="oc4" heading="Our Clients" subheading="" />
                                    </div>

                                </div>
                                <Clients />
                            </div>
                        </section> */}
            {/* Clients End */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepage;
