import React, { useEffect, useRef, useState } from 'react'
import "./NewTechnologyManagement.css"
import './NewOtherDept.css'
import { Link, useLocation, useParams } from 'react-router-dom';
import ContactUsDepartment from '../ContactUsDepartment';
// import HomeNotices from '../../Home/HomeNotices/HomeNotices';
import HomeNotices from '../../Home2/HomeNotices/HomeNotices'
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import breadcrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/PRM.jpg'
import ScrollToTop from '../../ScrollToTop';
import layer from '../../../Images_1/Departments/Images/pmrdmain.png'
import NewDepartmentTeam from '../NewDepartmentTeam/NewDepartmentTeam'
import NewSuccessStories from './NewSuccessStories/NewSuccessStories';
import ServiceImage1 from '../../../Images_1/Departments/DepartmentHomeImages/NewTechManagement.jpg'
import ServiceImage2 from '../../../Images_1/Departments/DepartmentHomeImages/RenewableManagement.jpg'
import ServiceImage3 from '../../../Images_1/Departments/DepartmentHomeImages/PetroluemManagement.jpg'
import ServiceImage4 from '../../../Images_1/Departments/DepartmentHomeImages/EnvironmentManagement.jpg'
// import ServiceImage5 from '../../../Images_1/Departments/DepartmentHomeImages/PetroleumResearchManagement.jpg'
// import ServiceImage01 from '../../../Images_1/Departments/Services/PRM images/1.1 Crude oil analysis.jpg'
// import ServiceImage02 from '../../../Images_1/Departments/Services/PRM images/1.2 Natural gas compositional analysis.jpg'
// import ServiceImage03 from '../../../Images_1/Departments/Services/PRM images/1.3 Water analysis.jpg'
// import ServiceImage04 from '../../../Images_1/Departments/Services/PRM images/1.4 Soil and sediment analysis.jpg'
// import ServiceImage05 from '../../../Images_1/Departments/Services/PRM images/1.5 Microbial analysis.jpg'
// import ServiceImage06 from '../../../Images_1/Departments/Services/PRM images/1.6 Preparation of DPR and PMC services for CBG plants.jpg'
// import ServiceImage07 from '../../../Images_1/Departments/Services/PRM images/1.7 Material analysis.jpg'

// import ServiceImage11 from '../../../Images_1/Departments/Services/PRM images/2.1 Mechanism to sequester CO2 biologically and geologically.jpg'
// import ServiceImage12 from '../../../Images_1/Departments/Services/PRM images/2.2 Development of biogas with zero CO2 emission mechanism.jpg'
// import ServiceImage13 from '../../../Images_1/Departments/Services/PRM images/2.3 Susceptibility of mixing of hydrogen with natural gas.jpg'
// import ServiceImage14 from '../../../Images_1/Departments/Services/PRM images/2.4 Qualitative water resource management and remedial measures to contain any further degradation.jpg'
// import ServiceImage15 from '../../../Images_1/Departments/Services/PRM images/2.5 Development of cellulose base super capacitor and battery.jpg'
// import ServiceImage21 from '../../../Images_1/Departments/Services/PRM images/3.1 Training and Skill Development.jpg'

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import HomeCounter from '../../Home2/HomeCounter/HomeCounter';
import HomeCounterNew from '../../Home2/HomeCounterNew/HomeCounterNew';
import OurDepartmentTeam from '../OurDepartmentTeam';
import pageData from "./json/PetroluemresearchDepartment.json"
import ComingSoonTeam from '../ComingSoonTeam';
import TheCounter from "../TheCounter"
import ScrollTrigger from 'react-scroll-trigger'
import OnScrollAnimation from '../../OnScrollAnimation';
import HomeNoticesDepartment from '../../Home2/HomeNotices/HomeNoticesDepartment';

import ntm from '../../../Images_1/Departments/DepartmentHomeImages/NTM.png'
import rm from '../../../Images_1/Departments/DepartmentHomeImages/RM.png'
import pm from '../../../Images_1/Departments/DepartmentHomeImages/PM.png'
import em from '../../../Images_1/Departments/DepartmentHomeImages/EM.png'
import { useDispatch } from 'react-redux';
import { aboutdetail } from './store/aboutdetailSlice';
import { Helmet } from 'react-helmet';

const RenewableManagement = () => {
    const { id } = useParams();
    const { id2 } = useParams();
    const { state } = useLocation();
    const containerWrapperRef = useRef(null);
    const topWrapperRef = useRef(null);
    const PRM = useRef([]);
    const PRM1 = useRef([]);
    const PRM2 = useRef([]);
    const [aboutdata, setAboutdata] = useState("");
    const [abouttitle, setAbouttitle] = useState("");
    const dispatch=useDispatch();
    useEffect(() => {

        // if (id == 5) {
        // if (topWrapperRef.current) {
        //     // Scroll the div into view
        //     topWrapperRef.current.scrollIntoView({ behavior: 'smooth' });

        //     // Set focus on the div
        //     topWrapperRef.current.focus();
        // }
        // } else {

        if (id == 1) {


            var index3 = pageData["services"].research.findIndex(
                (x) => x.id === id2
            );
            //   console.log(index3+"index")
            if (index3 !== null && PRM1.current[index3]) {



                PRM1.current[index3].scrollIntoView({ behavior: 'smooth' });
                PRM1.current[index3].focus();
            }

        } else if (id == 2) {


            var index3 = pageData["services"].training.findIndex(
                (x) => x.id === id2
            );
            if (index3 !== null && PRM2.current[index3]) {
                PRM2.current[index3].scrollIntoView({ behavior: 'smooth' });
                PRM2.current[index3].focus();
            }

        } else if (id == 0) {
            // console.log(pageData["services"].advisory)
            var index3 = pageData["services"].advisory.findIndex(
                (x) => x.id == id2
            );
            // console.log(id2 + "index" + index3)
            if (index3 !== null) {
                PRM.current[index3].scrollIntoView({ behavior: 'smooth' });
                PRM.current[index3].focus();
            }
            // }
        } else {

        }

        var jsonparams = {
            "modulename": "PRM",
        }
        dispatch(aboutdetail(jsonparams)).then((data) => {
            console.log(data.payload);
            if(data.payload.status=="1"||data.payload.status==1){
            setAbouttitle(data.payload.title);
            setAboutdata(data.payload.description)
            }      
        });

    }, []);

    const [counterState, setCounterState] = useState(false)


    const [SelectedService, setSelectedService] = useState(['', '', '']);
    const [selectedDiv, setSelectedDiv] = useState(id == 1 ? "R&D" : id == 2 ? "T&SD" : 'A&C');
    const activeBtnStyle = {
        // backgroundColor: '#d2611c',
        backgroundColor: '#7cba27',
        color: '#ffffff',
        transition: '0.4s ease-in-out',
    }
    return (
        <div>

<Helmet>
      <title>
      Oil and Gas Production Enhancement services in Gujarat | Oil and Gas Reserve
Certification
      </title>
      <meta name="keywords" content="Oil and Gas Production Enhancement services in Gujarat, Oil and Gas Reserve
Certification, Static and Dynamic Reservoir Modeling, Oil and Gas Production Enhancement,
Coal Bed Methane Exploration, SPE PRMS based Oil and Gas Reserve Certification"/>
<meta name="description" content="The Petroleum Research Management Group excels in hydrocarbon exploration tools,
branching into diverse research areas like Static and Dynamic Reservoir Modeling, Oil and Gas
Production Enhancement, Coal Bed Methane Exploration, SPE PRMS based Oil and Gas
Reserve Certification."/>
    </Helmet>
            <ScrollToTop />
            {/* <DepartmentHeader styles="header-transparent header-style-2" searchDisable buttonStyle="bg-color-white" /> */}
            <div ref={topWrapperRef} className='breadcrumb-margin'>
                <BreadCrumbs className="breadcrumb-text-class" parent="Departments" child="Petroleum Research Management" parentPath="/departments" imgPath={breadcrumbImage} />
            </div>
            <div className='new-tech-whole-section dep-sec-bg'>
                <section className='new-technology-department-about-sectionpmr'>

                    <img className="new-department-inner-image new-tech-image-translateCustomSlider3" src={layer} />
                    {/* <div> */}
                    {/* <img className="new-department-text-image" src={title} /> */}
                    <div className='new-tech-title-spacing'>
                        <h1 className='new-tech-custom-slider-changing-text  new-tech-image-translateCustomSlider heading-fonts'>{abouttitle==""?"Petroleum Research Management":abouttitle}</h1>

                        <h6 className='new-tech-custom-slider-changing-paragraph normal-text new-tech-image-translateCustomSlider1' style={{ textAlign: "justify" }}>{aboutdata==""?"The Petroleum Research Management Group excels in hydrocarbon exploration tools, branching into diverse research areas like synthetic combustion fuels, qualitative water resource management, CO2 sequestration and laboratory support to industries. Noteworthy achievements include numerous publications, research proposals, and patents, notably in seaweed cellulose-based technology. Future plans involve expanding facilities for industrial needs, advancing green hydrogen blending with natural gas, biogas-compressed biogas development and collaborating with institutes / industries for enhanced R & D.":aboutdata}</h6>
                    </div>
                    {/* </div> */}

                </section>
            </div>

            <section className='dep-sec-bg'>
                <div>
                    <section className='home-counterNew'>
                        <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
                            <div className='home-counterNew-container top-padding-zero'>
                                {/* <h2 className='home-counterNew-headingNew'>Success in Numbers</h2> */}
                                <div className='counter-div-containerNew'>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={41} duration="300" />}
                                            {/* <span className='counter-symbolNew'>&nbsp; {"under process"}</span> */}
                                        </h3>
                                        <span className='counter-textNew normal-text sub-heading'>Journals</span>
                                        <span className='counter-textNew normal-text'>Publications</span>
                                    </div>
                                    {/* <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew'>6
                                            {counterState && <TheCounter maxValue={1} duration="300" />}
                                            <span className='counter-symbolNew'>&nbsp; {"under process"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text sub-heading'>Conference</span>
                                        <span className='counter-textNew normal-text'>Publications</span>
                                    </div> */}
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={2} duration="300" />}
                                            {/* <span className='counter-symbolNew'>&nbsp; {" awarded"}</span> */}
                                        </h3>
                                        <span className='counter-textNew normal-text sub-heading'>Ph D. Supervision</span>
                                        <span className='counter-textNew normal-text'>awarded</span>
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={2} duration="300" />}
                                            {/* <span className='counter-symbolNew'>&nbsp;{"ongoing"}</span> */}
                                        </h3>
                                        <span className='counter-textNew normal-text sub-heading'>Ph D. Supervision</span>
                                        <span className='counter-textNew normal-text'>ongoing</span>
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={2} duration="300" />}
                                            {/* <span className='counter-symbolNew'>&nbsp; {"granted"}</span> */}
                                        </h3>
                                        <span className='counter-textNew normal-text sub-heading'>Patents</span>
                                        <span className='counter-textNew normal-text'>granted</span>
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={1} duration="300" />}
                                            {/* <span className='counter-symbolNew'>&nbsp; {"under process"}</span> */}
                                        </h3>
                                        <span className='counter-textNew normal-text sub-heading'>Patent</span>
                                        <span className='counter-textNew normal-text'>under process</span>
                                    </div>
                                    {/* <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew'>
                                            {counterState && <TheCounter maxValue={120} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text sub-heading'>Projects </span>
                                        <span className='counter-textNew normal-text sub-heading'> Executed</span>
                                        
                                    </div> */}
                                </div>
                            </div>
                        </ScrollTrigger>
                    </section>

                </div>
                {/* <HomeCounterNew />  */}
            </section>

            <section className='dep-sec-bg'>
                {/* <div ref={containerWrapperRef} className="heading-container">
                    <h4>Our Services</h4>
                    <h4 id="title1" className="title-animation-about-us">Services We Provide</h4>
                    <div className=" div-for-hr-animation"><hr id="hr1" className="animate-hr-tag-about-us" /><hr id="hr2" className="animate-hr-tag-about-us" />
                    </div>
                </div> */}
                <OnScrollAnimation title1="prm1" title2="prm2" hr1="prm3" hr2="prm4" heading="Services We Provide" subheading="" />
                <div className='top-container'>
                    <div className='service-tab-container'>
                        <Link className='service-button twenty-sixteen-fonts one' onClick={() => { setSelectedDiv('A&C') }} style={selectedDiv == 'A&C' ? activeBtnStyle : {}}>Advisory</Link>
                        {/* <span style={selectedDiv == 'A&C' || selectedDiv == 'R&D' ? { opacity: '0' } : { color: "#7cba27" }}> | </span> */}
                        <Link className='service-button twenty-sixteen-fonts one' onClick={() => { setSelectedDiv('R&D') }} style={selectedDiv == 'R&D' ? activeBtnStyle : {}}>Research</Link>
                        {/* <span style={selectedDiv == 'R&D' || selectedDiv == 'T&SD' ? { opacity: '0' } : { color: "#7cba27" }}> | </span> */}
                        <Link className='service-button twenty-sixteen-fonts' onClick={() => { setSelectedDiv('T&SD') }} style={selectedDiv == 'T&SD' ? activeBtnStyle : {}}>Training</Link>
                    </div>
                    {/* <div className="Totalcontainer">
                        <span className='total-text'>Total Services <span style={{ color: "#7cba27" }}> | </span> {pageData.services.advisory.length + pageData.services.research.length  + pageData.services.training.length}  </span>
                    </div> */}
                </div>
                <div className='dps-main'>
                    <div className='dps-full'>
                        <div id='A&C' style={selectedDiv == 'A&C' ? { display: 'block' } : { display: 'none' }}>

                            {
                                pageData && pageData["services"].advisory.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?

                                        <div div key={data.id} ref={(el) => (PRM.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (PRM.current[index] = el)} className='dps-row' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> :
                                                        <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    // < div className='dps-row' >
                                    //     <div key={data.id} ref={(el) => (PRM.current[index] = el)} className='dps-text-content normal-text '>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>

                                    //     </div>
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //         <div className="d1">
                                    //             <div className="circle-ripple">
                                    //                 <div className='dps-numbering'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div> :

                                    // < div key={data.id} ref={(el) => (PRM.current[index] = el)} className='dps-row' >
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //     </div>
                                    //     <div className='dps-text-content normal-text   dps-text-content-2'>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>
                                    //         <div className="d1">
                                    //             <div className="circle-ripple-2">
                                    //                 <div className='dps-numbering-2'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                })
                            }

                            {/* <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Crude oil analysis</h4>
                                        <span>
                                            Petroleum Research Laboratory, where we specialize in the comprehensive analysis of crude oil.
                                            Our facility offers a wide range of test From basic physical properties such as density,
                                            viscosity, and pour point to more complex analyses including wax content, asphaltene content,
                                            and carbon residue, we cover every aspect essential for understanding crude oil behaviour
                                            and refining processes. Using advanced instrumentation and techniques,
                                            for examine various aspects of crude oil characteristics.
                                        </span>
                                    </div>
                                    <div className='dps-numbering'><span>1</span></div>
                                </div>
                                <div className='dps-img-content'>
                                    <img src={ServiceImage01} alt="hello" />
                                </div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={ServiceImage02} alt="hello" />
                                </div>
                                <div className='dps-text-content dps-text-content-2'>
                                    <div>
                                        <h4>Natural gas compositional analysis</h4>
                                        <span>
                                            Our facility provides an extensive array of analyses, encompassing the determination of methane,
                                            ethane, propane, butanes, pentanes, hexane, carbon dioxide, and nitrogen concentrations.
                                            other key parameters, such as heating value and specific gravity,
                                            we provide thorough evaluations essential for optimizing natural gas utilization and processing.
                                        </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>2</span></div>

                                </div>

                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Water analysis</h4>
                                        <span>
                                            Water testing parameters essential for assessing water quality. From pH and turbidity to chloride, sulphate,
                                            and dissolved oxygen, our comprehensive testing services cover a wide range of physical and chemical constituents.
                                            Adhering to Indian standards, we ensure accurate and reliable results crucial for safeguarding water resources and
                                            promoting environmental sustainability. Whether for regulatory compliance, industrial processes, or public health,
                                            our laboratory is dedicated to providing the highest quality testing services tailored to your specific needs.
                                        </span>

                                    </div>
                                    <div className='dps-numbering'><span>3</span></div>


                                </div>
                                <div className='dps-img-content'>
                                    <img src={ServiceImage03} alt="hello" />
                                </div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={ServiceImage04} alt="hello" />
                                </div>
                                <div className='dps-text-content dps-text-content-2'>
                                    <div>
                                        <h4>Soil and sediment analysis</h4>
                                        <span>
                                            Metal Analysis and Total Organic Carbon (TOC) Analysis services involve detecting and quantifying various
                                            metals in soil and sediment samples, alongside assessing the organic content. The presence of organic matter
                                            in soil is crucial for understanding soil fertility and nutrient cycling. Meanwhile, metal analysis aids in
                                            identifying contamination sources, evaluating environmental impacts, and directing remediation strategies.
                                        </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>4</span></div>
                                </div>

                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Microbial analysis</h4>
                                        <span>
                                            Water and soil are ecosystems teeming with diverse microbial communities that play crucial roles in nutrient cycling,
                                            ecosystem stability, and even human health. However, certain microorganisms can also pose risks, such as pathogens that
                                            threaten human and environmental well-being. Serving as indicators of potential pathogenic microorganisms,
                                            coliform bacteria assessment helps ensure the safety of water for drinking, recreational, and agricultural purposes.
                                        </span>
                                    </div>
                                    <div className='dps-numbering'><span>5</span></div>
                                </div>
                                <div className='dps-img-content'>
                                    <img src={ServiceImage05} alt="hello" />
                                </div>
                            </div>
                            <div className='dps-row' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}}>
                                <div style={SelectedService[0] == 'srv1' ? { display: 'none' } : { display: 'block' }} className='dps-img-content'>
                                    <img src={ServiceImage06} alt="hello" />
                                </div>
                                <div className={`dps-text-content dps-text-content-2 ${SelectedService[0] === 'srv1' ? "dp-bg-design" : ""}`} style={SelectedService[0] == 'srv1' ? { width: '100%', padding: '100px 40px 70px 40px' } : {}}>
                                    <div>
                                        <h4>Preparation of DPR and PMC services for CBG plants</h4>
                                        <span>
                                            Conducting a comprehensive feasibility study to assess the viability of establishing
                                            a CBG plant in a particular location. This involves analysing factors such as feedstock availability,
                                            technology options, market demand, regulatory requirements, and financial viability.

                                        </span>

                                        <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                            <span>
                                                Evaluating different technology options for CBG production, such as anaerobic digestion, gasification, or thermal conversion,
                                                based on factors like feedstock characteristics, scalability, efficiency, and cost-effectiveness.
                                                Developing a detailed project report outlining the technical, financial, and environmental aspects of the CBG plant project.
                                                This includes engineering design, process flow diagrams, equipment specifications, project cost estimation, revenue projections,
                                                risk assessment, and environmental impact assessment.
                                            </span>
                                        </div>

                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                        </div>
                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                        </div>

                                    </div>
                                    <div style={SelectedService[0] == 'srv1' ? { display: 'none' } : { display: 'block' }} className='dps-numbering-2'><span>6</span></div>

                                </div>

                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Material analysis</h4>
                                        <span>
                                            Evaluating the microscopic characteristics and Thickness survey of polyethylene (PE) and galvanized iron (GI)
                                            materials for quality assurance and ensure compliance with standards. Mapping the extent and location
                                            of corrosion on pipeline materials to assess structural integrity. Conducting destructive testing for irreversibly alter materials to
                                            assess their properties and durability. Examining negative impacts on gas stove burners.
                                        </span>

                                    </div>
                                    <div className='dps-numbering'><span>7</span></div>


                                </div>
                                <div className='dps-img-content'>
                                    <img src={ServiceImage07} alt="hello" />
                                </div>
                            </div> */}
                        </div>

                        <div id='R&D' style={selectedDiv == 'R&D' ? { display: 'block' } : { display: 'none' }}>


                            {
                                pageData && pageData["services"].research.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?
                                        <div div key={data.id} ref={(el) => (PRM1.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (PRM1.current[index] = el)} className='dps-row' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> :
                                                        <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    //  < div className='dps-row' >
                                    //     <div key={data.id} ref={(el) => (PRM1.current[index] = el)} className='dps-text-content normal-text '>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>

                                    //     </div>
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //         <div className="d1">
                                    //             <div className="circle-ripple">
                                    //                 <div className='dps-numbering'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div> : 
                                    // < div key={data.id} ref={(el) => (PRM1.current[index] = el)} className='dps-row' >
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //     </div>
                                    //     <div className='dps-text-content normal-text   dps-text-content-2'>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>
                                    //         <div className="d1">
                                    //             <div className="circle-ripple-2">
                                    //                 <div className='dps-numbering-2'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                })
                            }


                            {/* <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Mechanism to sequester CO2 biologically and geologically</h4>
                                        <span>
                                           </span>
                                    </div>
                                    <div className='dps-numbering'><span>1</span></div>
                                </div>
                                <div className='dps-img-content'>
                                    <img src={ServiceImage11} alt="hello" />
                                </div>
                            </div>
                            <div className='dps-row' id='srv2' style={SelectedService[1] == 'srv2' ? { height: '100%' } : {}}>
                                <div className='dps-img-content' style={SelectedService[1] == 'srv2' ? { display: 'none' } : { display: 'block' }}>
                                    <img src={ServiceImage12} alt="hello" />
                                </div>
                                <div className={`dps-text-content dps-text-content-2 ${SelectedService[1] === 'srv2' ? "dp-bg-design" : ""}`} style={SelectedService[1] == 'srv2' ? { width: '100%', padding: '100px 40px 70px 40px' } : {}}>
                                    <div>
                                        <h4>Development of biogas with zero CO2 emission mechanism</h4>
                                        <span>
                                            The development of biogas with a zero CO2 emission mechanism involves harnessing organic waste materials,
                                            such as agricultural residues or food waste, in anaerobic digesters. Through this process,
                                            microorganisms break down the organic matter, producing biogas primarily composed of methane.
                                        </span>
                                        <div style={SelectedService[1] == 'srv2' ? { display: 'block' } : { display: 'none' }}>
                                            <span>
                                                To achieve zero CO2 emissions, the captured methane is utilized as a renewable energy source, displacing fossil fuels, while any CO2 emitted during digestion is offset by the carbon captured during the growth of the organic material, thus maintaining a carbon-neutral cycle. Advanced technologies like carbon capture and utilization or enhancement of digestion processes aim to further minimize emissions,
                                                making biogas a sustainable energy solution with a minimal environmental footprint.
                                            </span>
                                        </div>

                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService([SelectedService[0], 'srv2', SelectedService[2]]) }} style={SelectedService[1] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                        </div>

                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService([SelectedService[0], '', SelectedService[2]]) }} style={SelectedService[1] == 'srv2' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                        </div>
                                    </div>
                                    <div className='dps-numbering-2' style={SelectedService[1] == 'srv2' ? { display: 'none' } : { display: 'block' }}><span>2</span></div>

                                </div>

                            </div>
                            <div className='dps-row' id='srv3' style={SelectedService[2] == 'srv3' ? { height: '100%' } : {}}>
                                <div className={`dps-text-content dps-text-content-2 ${SelectedService[2] === 'srv3' ? "dp-bg-design" : ""}`} style={SelectedService[2] == 'srv3' ? { width: '100%', padding: '100px 40px 70px 40px' } : {}}>
                                    <div>
                                        <h4>Susceptibility of mixing of hydrogen with natural gas</h4>
                                        <span>
                                            Experimental efforts focus on assessing the compatibility of current components, structures,
                                            and operational conditions with the unique characteristics of hydrogen and natural gas blends.

                                        </span>

                                        <span style={SelectedService[2] == 'srv3' ? { display: 'block' } : { display: 'none' }}>
                                            <span>
                                                Of particular concern is the potential adverse effects of hydrogen diffusion on the mechanical properties of pipeline materials.
                                                As hydrogen introduces new challenges for flaw detection compared to pure natural gas, there is a heightened need for sensitive
                                                monitoring equipment to ensure pipeline integrity and safety. optimal blend ratio to achieve desired performance characteristics
                                                while adhering to safety standards. material testing is conducted to evaluate how the introduction of hydrogen affects the
                                                properties and integrity of pipeline materials.
                                                the aim is to optimize infrastructure for the safe and efficient transmission of hydrogen-natural gas blends.
                                            </span>
                                        </span>
                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService([SelectedService[0], SelectedService[1], 'srv3']) }} style={SelectedService[2] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                        </div>
                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService([SelectedService[0], SelectedService[1], '']) }} style={SelectedService[2] == 'srv3' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                        </div>

                                    </div>
                                    <div className='dps-numbering'  style={SelectedService[2] == 'srv3' ? { display: 'none' } : { display: 'block' }}><span>3</span></div>
                                </div>
                                <div className='dps-img-content' style={SelectedService[2] == 'srv3' ? { display: 'none' } : { display: 'block' }}>
                                    <img src={ServiceImage13} alt="hello" />
                                </div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={ServiceImage14} alt="hello" />
                                </div>
                                <div className='dps-text-content dps-text-content-2'>
                                    <div>
                                        <h4>Qualitative water resource management and remedial measures to contain any further degradation</h4>
                                        <span>
                                            </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>4</span></div>
                                </div>

                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Development of cellulose base super capacitor and battery</h4>
                                        <span>
                                           </span>

                                    </div>
                                    <div className='dps-numbering'><span>5</span></div>


                                </div>
                                <div className='dps-img-content'>
                                    <img src={ServiceImage15} alt="hello" />
                                </div>
                            </div> */}
                        </div>

                        <div id='T&SD' style={selectedDiv == 'T&SD' ? { display: 'block' } : { display: 'none' }}>

                            {
                                pageData && pageData["services"].training.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?
                                        <div div key={data.id} ref={(el) => (PRM2.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (PRM2.current[index] = el)} className='dps-row' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> :
                                                        <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    //  < div className='dps-row' >
                                    //     <div key={data.id} ref={(el) => (PRM2.current[index] = el)} className='dps-text-content normal-text '>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>

                                    //     </div>
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //         <div className="d1">
                                    //             <div className="circle-ripple">
                                    //                 <div className='dps-numbering'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div> : 
                                    // < div key={data.id} ref={(el) => (PRM2.current[index] = el)} className='dps-row' >
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //     </div>
                                    //     <div className='dps-text-content normal-text dps-text-content-2'>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>
                                    //         <div className="d1">
                                    //             <div className="circle-ripple-2">
                                    //                 <div className='dps-numbering-2'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>;
                                })
                            }



                            {/* <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Internship</h4>
                                        <span>
                                        Internship offers an immersive experience designed to bridge academic knowledge with practical application. Through hands-on opportunities, students delve into the complexities of environmental science, exploring the intersections with biotechnology, microbiology, chemistry, and other life sciences
                                             </span>
                                    </div>
                                    <div className='dps-numbering'><span>1</span></div>
                                </div>
                                <div className='dps-img-content'>
                                    <img src={ServiceImage21} alt="hello" />
                                </div>
                            </div> */}
                            {/* <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src='https://xoha.wpengine.com/wp-content/uploads/2022/05/filler-img-3.jpg' alt="hello" />
                                </div>
                                <div className='dps-text-content dps-text-content-2'>
                                    <div>
                                        <h4>Market Research</h4>
                                        <span>
                                            Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
                                            accumsan lacus vel facilisis. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>2</span></div>

                                </div>

                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Market Research</h4>
                                        <span>
                                            Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
                                            accumsan lacus vel facilisis. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </span>

                                    </div>
                                    <div className='dps-numbering'><span>3</span></div>


                                </div>
                                <div className='dps-img-content'>
                                    <img src='https://xoha.wpengine.com/wp-content/uploads/2022/05/filler-img-3.jpg' alt="hello" />
                                </div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src='https://xoha.wpengine.com/wp-content/uploads/2022/05/filler-img-3.jpg' alt="hello" />
                                </div>
                                <div className='dps-text-content dps-text-content-2'>
                                    <div>
                                        <h4>Market Research</h4>
                                        <span>
                                            Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
                                            accumsan lacus vel facilisis. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>4</span></div>
                                </div>

                            </div> */}
                        </div>

                    </div>
                </div>
            </section>

            {/* Success Stories Start */}
            <section className='dep-sec-bg'>
                <NewSuccessStories departmentId={5} />
            </section>
            {/* Success Stories End */}

            {/* <section>
                <HomeCounter />
            </section> */}

            <section className='dep-sec-bg'>
                {/* <NewDepartmentTeam /> */}
                <OurDepartmentTeam teamData={pageData.team} department={5} />
                {/* <ComingSoonTeam /> */}
            </section>

            <section className='youtube-video-link-section'>
               
                <div className='video-container'>
                <iframe
                        src="https://www.youtube.com/embed/xePvrCA9L_Y?si=UPmW2OVkE2UppgY5"
                        title="YouTube video player"
                        frameborder="1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                        className='youtube-video-2'>
                    </iframe>
                    <iframe
                        src="https://www.youtube.com/embed/yAJIxmTncPc?si=J11OMLK6Gr1pBpgq"
                        title="YouTube video player"
                        frameborder="1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                        className='youtube-video-2'>
                    </iframe>
                    <iframe
                        src="https://www.youtube.com/embed/x2_Q_wrH270?si=mj4rmbgak0hOVRuX"
                        title="YouTube video player"
                        frameborder="1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                        className='youtube-video-2'>
                    </iframe>
                </div>
            </section>

            <section className='department-notice-spacing'>
                <HomeNoticesDepartment department={5} />
            </section>

            {/* <section>
                <OurDepartmentTeam />
            </section> */}


            <section>
                <ContactUsDepartment department={4} />
            </section>

            {/* <section className="section-lgb other-dep-check-out-div">
                <div className="container pbmit-col-stretched-yes pbmit-col-right">

                    <div className="row main-div">
                        <h2 className='pbmit-service-title data-direction dd-mb'>Other Depatments To Checkout</h2>
                        <div className="col-12">

                            <div className="pbmit-col-stretched-right">

                                <div className="swiper-slider" data-autoplay="true" data-loop="true" data-dots="false" data-arrows="false" data-columns="4" data-margin="30" data-effect="slide">
                                    <div className="swiper-wrapper">
                                        <Swiper
                                            modules={[Navigation, Scrollbar, A11y, Autoplay]}
                                            spaceBetween={10}
                                            slidesPerView={4}
                                            

                                            loop={true}>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    

                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage1} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Renewable Management</Link>
                                                                        </h3>
                                                                        <div className="pbmit-service-category">
                                                                           
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>

                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage2} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Petroleum Management</Link>
                                                                        </h3>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage3} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Environment Management</Link>
                                                                        </h3>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage4} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Petroleum Research Management</Link>
                                                                        </h3>
                                                                        

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* </div> */}
            <section className="rs-service-grid-3 pt-70 pb-120">
                {/* <div className="heading-container">
                    <h4 id="title1" className="title-animation-about-us">Other Departments to Checkout</h4>
                    <div className=" div-for-hr-animation"><hr id="hr1" className="animate-hr-tag-about-us" /><hr id="hr2" className="animate-hr-tag-about-us" />
                    </div>
                </div> */}
                <OnScrollAnimation title1="odcprm1" title2="odcprm2" hr1="odcprm3" hr2="odcprm4" heading="Other Departments to Checkout" subheading="" />
                <div className='nod-container'>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage1} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={ntm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/new-technology-management/5/0">New Technology<br /> Management</Link></h4>
                                    {/* <p>Petroleum  Management </p> */}
                                    <Link to="/departments/new-technology-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage2} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={rm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/renewable-management/5/0">Renewable<br /> Management</Link></h4>
                                    {/* <p> Renewable Management </p> */}
                                    <Link to="/departments/renewable-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage3} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={pm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/petroleum-management/5/0">Petroleum<br /> Management</Link></h4>
                                    {/* <p>Petroleum Management </p> */}
                                    <Link to="/departments/petroleum-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage4} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={em} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/environment-management/5/0">Environment<br /> Management</Link></h4>
                                    {/* <p> Environment Management  </p> */}
                                    <Link to="/departments/environment-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default RenewableManagement;
