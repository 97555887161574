import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicRequest } from "../../../../constants";


export const aboutdetail= createAsyncThunk(
  "aboutdetail",
  async (data) => {
     const json=JSON.stringify(data)
    // console.log("here")
    const res = await publicRequest.post("/getaboutdatabyid", json);
    if (res) {
      // console.log(res);
    //   if (res.data.status) {
        
    //     document.getElementById('contact-status').innerText = res.data.message;
    //     setTimeout(function() {
    //       document.getElementById('contact-status').innerText = '';
    //   }, 10000);
     // }
      return res.data.data
    }

  }
);


const aboutdetailSlice = createSlice({
  name: "aboutdetail",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(aboutdetail.fulfilled, (state, action) => {
      return action.payload
    })
  }
});

export default aboutdetailSlice.reducer;  