import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import BreadCrumbImage from "../../Images_1/CommonSection/Braedcrumbs/gallery.jpg";
import Feedback from "../../Images_1/CommonSection/Common/feedback.jpg";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  Icon,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Rating,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { useEffect, useRef, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import {
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { blue, red } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useNavigate, useParams } from "react-router-dom";
import { addapplication } from "./store/AddApplication";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../ScrollToTop";
import CloseIcon from "@mui/icons-material/Close";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Loader";
import {
  getsingleapplication,
  singleapplication,
} from "../Carrers/store/SingleApplicationDataSlice";
import { FormLabel } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { sendfeedbackcall } from "./store/SendFeedback";

const AddNewJobApplication = (props) => {
  const validationSchema = Yup.object().shape({
    mobileNo: Yup.string().matches(
      /^[0-9]{10}$/,
      "Mobile number is required And Mobile number must be exactly 10 digits"
    ),
    confirmMobileNo: Yup.string().oneOf(
      [Yup.ref("mobileNo"), null],
      "Mobile number must match"
    ),
    wmobileNo: Yup.string().matches(
      /^[0-9]{10}$/,
      "Whatsapp Mobile number is required And Mobile number must be exactly 10 digits"
    ),
    wconfirmMobileNo: Yup.string().oneOf(
      [Yup.ref("wmobileNo"), null],
      "Whatsapp Mobile number must match"
    ),
    Title: Yup.string().required("Title is required"),
    firstName: Yup.string().required("Firstname is required"),
    lastName: Yup.string().required("Lastname is required"),
    emailId: Yup.string().matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "Email is not valid"
    ),
    confirmEmailId: Yup.string().oneOf(
      [Yup.ref("emailId"), null],
      "Email must match"
    ),
    address: Yup.string().required("Address is required"),
    experience: Yup.string().required("Experience Level is required"),
    gender: Yup.string().required("Gender is required"),
    currentCity: Yup.string().required("Current city is required"),
    currentPincode: Yup.string().required("Current Pincode is required"),
    currentState: Yup.string().required("Current state is required"),
    nativeState: Yup.string().required("Native state is required"),
    dateOfBirth: Yup.date().required("Date of Birth is required"),
    minimumnoticePeriod: Yup.string().required(
      "Minimumnotice Period is required"
    ),
  });
  const validationSchemaexp = Yup.object().shape({
    mobileNo: Yup.string()
      .test(
        "len",
        "Mobile number Must be exactly 10 characters",
        (val) => val.length === 10
      )
      .required("Mobile number is required"),
    confirmMobileNo: Yup.string()
      .test(
        "len",
        "Mobile number Must be exactly 10 characters",
        (val) => val.length === 10
      )
      .required("Mobile number is required")
      .oneOf([Yup.ref("mobileNo"), null], "Mobile number must match"),
    wmobileNo: Yup.string()
      .test(
        "len",
        "Whatsapp Mobile number Must be exactly 10 characters",
        (val) => val.length === 10
      )
      .required("Whatsapp Mobile number is required"),
    wconfirmMobileNo: Yup.string()
      .test(
        "len",
        "Whatsapp Mobile number Must be exactly 10 characters",
        (val) => val.length === 10
      )
      .required("Whatsapp Mobile number is required")
      .oneOf([Yup.ref("wmobileNo"), null], "Whatsapp Mobile number must match"),
      Title: Yup.string().required("Title is required"),
    firstName: Yup.string().required("Firstname is required"),
    lastName: Yup.string().required("Lastname is required"),
    emailId: Yup.string().matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "Email is not valid"
    ),
    confirmEmailId: Yup.string().oneOf(
      [Yup.ref("emailId"), null],
      "Email must match"
    ),
    address: Yup.string().required("Address is required"),
    experience: Yup.string().required("Experience Level is required"),
    gender: Yup.string().required("Gender is required"),
    currentCity: Yup.string().required("Current city is required"),
    currentPincode: Yup.string().matches(
      /^[0-9]{6}$/,
      "Current Pincode is required And Current Pincode must be exactly 6 digits"
    ),
    currentState: Yup.string().required("Current state is required"),
    nativeState: Yup.string().required("Native state is required"),
    dateOfBirth: Yup.date().required("Date of Birth is required"),
    minimumnoticePeriod: Yup.string().required(
      "Minimumnotice Period is required"
    ),
    currentDesignation: Yup.string().required("current Designationis required"),
    currentOrganization: Yup.string().required(
      "current Organization is required"
    ),
    netToHandSalary: Yup.number().required("Monthly Take Home Salary required"),
    grossSalary: Yup.number()
      .required("Monthly Gross Salary is required")
      .moreThan(
        Yup.ref("netToHandSalary"),
        "Monthly Gross Salary must be greater than Monthly Take Home Salary"
      ),
    // experince: Yup.string().required("Experince Years is required")
  });
  const { id, id2, id3, id4, id5, id6,istartdate } = useParams();
  const [loading, setLoading] = useState(false);
  console.log(id, id2, id3, id4, id5,istartdate);
  console.log("id,id2,id3,id4,id5");
  const [cfiles, setCFiles] = useState(null);
  var IndState = [
    {
      id: "1",
      name: "Andaman and Nicobar Islands",
      country_id: "101",
      label: "Andaman and Nicobar Islands",
      value: "1",
    },
    {
      id: "2",
      name: "Andhra Pradesh",
      country_id: "101",
      label: "Andhra Pradesh",
      value: "2",
    },
    {
      id: "3",
      name: "Arunachal Pradesh",
      country_id: "101",
      label: "Arunachal Pradesh",
      value: "3",
    },
    {
      id: "4",
      name: "Assam",
      country_id: "101",
      label: "Assam",
      value: "4",
    },
    {
      id: "5",
      name: "Bihar",
      country_id: "101",
      label: "Bihar",
      value: "5",
    },
    {
      id: "6",
      name: "Chandigarh",
      country_id: "101",
      label: "Chandigarh",
      value: "6",
    },
    {
      id: "7",
      name: "Chhattisgarh",
      country_id: "101",
      label: "Chhattisgarh",
      value: "7",
    },
    {
      id: "8",
      name: "Dadra and Nagar Haveli",
      country_id: "101",
      label: "Dadra and Nagar Haveli",
      value: "8",
    },
    {
      id: "9",
      name: "Daman and Diu",
      country_id: "101",
      label: "Daman and Diu",
      value: "9",
    },
    {
      id: "10",
      name: "Delhi",
      country_id: "101",
      label: "Delhi",
      value: "10",
    },
    {
      id: "11",
      name: "Goa",
      country_id: "101",
      label: "Goa",
      value: "11",
    },
    {
      id: "12",
      name: "Gujarat",
      country_id: "101",
      label: "Gujarat",
      value: "12",
    },
    {
      id: "13",
      name: "Haryana",
      country_id: "101",
      label: "Haryana",
      value: "13",
    },
    {
      id: "14",
      name: "Himachal Pradesh",
      country_id: "101",
      label: "Himachal Pradesh",
      value: "14",
    },
    {
      id: "15",
      name: "Jammu and Kashmir",
      country_id: "101",
      label: "Jammu and Kashmir",
      value: "15",
    },
    {
      id: "16",
      name: "Jharkhand",
      country_id: "101",
      label: "Jharkhand",
      value: "16",
    },
    {
      id: "17",
      name: "Karnataka",
      country_id: "101",
      label: "Karnataka",
      value: "17",
    },
    {
      id: "18",
      name: "Kenmore",
      country_id: "101",
      label: "Kenmore",
      value: "18",
    },
    {
      id: "19",
      name: "Kerala",
      country_id: "101",
      label: "Kerala",
      value: "19",
    },
    {
      id: "20",
      name: "Lakshadweep",
      country_id: "101",
      label: "Lakshadweep",
      value: "20",
    },
    {
      id: "21",
      name: "Madhya Pradesh",
      country_id: "101",
      label: "Madhya Pradesh",
      value: "21",
    },
    {
      id: "22",
      name: "Maharashtra",
      country_id: "101",
      label: "Maharashtra",
      value: "22",
    },
    {
      id: "23",
      name: "Manipur",
      country_id: "101",
      label: "Manipur",
      value: "23",
    },
    {
      id: "24",
      name: "Meghalaya",
      country_id: "101",
      label: "Meghalaya",
      value: "24",
    },
    {
      id: "25",
      name: "Mizoram",
      country_id: "101",
      label: "Mizoram",
      value: "25",
    },
    {
      id: "26",
      name: "Nagaland",
      country_id: "101",
      label: "Nagaland",
      value: "26",
    },
    {
      id: "27",
      name: "Narora",
      country_id: "101",
      label: "Narora",
      value: "27",
    },
    {
      id: "28",
      name: "Natwar",
      country_id: "101",
      label: "Natwar",
      value: "28",
    },
    {
      id: "29",
      name: "Odisha",
      country_id: "101",
      label: "Odisha",
      value: "29",
    },
    {
      id: "30",
      name: "Paschim Medinipur",
      country_id: "101",
      label: "Paschim Medinipur",
      value: "30",
    },
    {
      id: "31",
      name: "Pondicherry",
      country_id: "101",
      label: "Pondicherry",
      value: "31",
    },
    {
      id: "32",
      name: "Punjab",
      country_id: "101",
      label: "Punjab",
      value: "32",
    },
    {
      id: "33",
      name: "Rajasthan",
      country_id: "101",
      label: "Rajasthan",
      value: "33",
    },
    {
      id: "34",
      name: "Sikkim",
      country_id: "101",
      label: "Sikkim",
      value: "34",
    },
    {
      id: "35",
      name: "Tamil Nadu",
      country_id: "101",
      label: "Tamil Nadu",
      value: "35",
    },
    {
      id: "36",
      name: "Telangana",
      country_id: "101",
      label: "Telangana",
      value: "36",
    },
    {
      id: "37",
      name: "Tripura",
      country_id: "101",
      label: "Tripura",
      value: "37",
    },
    {
      id: "38",
      name: "Uttar Pradesh",
      country_id: "101",
      label: "Uttar Pradesh",
      value: "38",
    },
    {
      id: "39",
      name: "Uttarakhand",
      country_id: "101",
      label: "Uttarakhand",
      value: "39",
    },
    {
      id: "40",
      name: "Vaishali",
      country_id: "101",
      label: "Vaishali",
      value: "40",
    },
    {
      id: "41",
      name: "West Bengal",
      country_id: "101",
      label: "West Bengal",
      value: "41",
    },
  ];
  const [catalogImages, setCatalogImages] = useState([]);
  var imageCounter = 0;
  var imageCounteruser = 0;
  const [ufiles, setUFiles] = useState(null);
  const [userImages, setUserImages] = useState([]);
  const [cfilesbd, setCFilesbd] = useState(null);
  const [catalogImagesbd, setCatalogImagesbd] = useState([]);
  var imageCounterbd = 0;
  const [cfilesud, setCFilesud] = useState(null);

  const [eduupload, setEDUupload] = useState([]);
  const [catalogImagesud, setCatalogImagesud] = useState([]);
  var imageCounterud = 0;
  const [cfilescv, setCFilescv] = useState(null);
  const [catalogImagescv, setCatalogImagescv] = useState([]);
  var imageCountercv = 0;
  const [isExperienced, setIsExperienced] = useState(true);
  const [isrelocated, setisrelocated] = useState(false);
  const [isFresher, setisFresher] = useState(true);
  const [eduValidation, seteduValidation] = useState([]);
  const [eduValidationtext, seteduValidationtext] = useState([]);
  const [Validation, setValidation] = useState(validationSchema);

  const defaultValues = {
    positionName: id2,
    Title:"",
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNo: "",
    confirmMobileNo: "",
    wmobileNo: "",
    wconfirmMobileNo: "",
    emailId: "",
    confirmEmailId: "",
    address: "",
    currentPincode: "",
    currentCity: "",
    currentState: "",
    nativeState: "",

    dateOfBirth: undefined,
    minimumnoticePeriod: undefined,
    readyToRelocate: true,
    age: "",
    gender: "",
    experience: "experienced",

    currentDesignation: "",
    currentOrganization: "",
    experince: "0",
    netToHandSalary: 0,
    grossSalary: 0,

    //     positionName
    // firstName
    // middleName
    // lastName
    // mobileNo
    // emailId
    // address
    // pincode
    // currentCity
    // currentState
    // nativeState
    // dateOfBirth
    // currentDesignation
    // currentOrganization
    // netToHandSalary
    // grossSalary
    // experience
    // minimumnoticePeriod
    // age
    // noticeperiod

    // dobProofFile: "",

    // salarySlipFile: "",
    // hscCompletedYear: undefined,
    // hscBoradUniversity: "",
    // hscSubjects: "",
    // hscPercentage: undefined,
    // diplomaCompletedYear: undefined,
    // diplomaBoradUniversity: "",
    // diplomaSubjects: "",
    // diplomaPercentage: undefined,
    // graduationCompletedYear: undefined,
    // graduationBoradUniversity: "",
    // graduationSubjects: "",
    // graduationPercentage: undefined,
    // postGraduationCompletedYear: undefined,
    // postGraduationBoradUniversity: "",
    // postGraduationSubjects: "",
    // postGraduationPercentage: undefined,
    // pgDiplomaCompletedYear: undefined,
    // pgDiplomaBoradUniversity: "",
    // pgDiplomaSubjects: "",
    // pgDiplomaPercentage: undefined,
    // mbaCompletedYear: undefined,
    // mbaBoradUniversity: "",
    // mbaSubjects: "",
    // mbaPercentage: undefined,
    // doctoralCompletedYear: undefined,
    // doctoralBoradUniversity: "",
    // doctoralSubjects: "",
    // doctoralPercentage: undefined,
    // firstOrganizationName: "",
    // firstDesignation: "",
    // firstDateOfJoining: undefined,
    // firstDateOfRelieving: undefined,
    // firstServiceLength: undefined,
    // firstSalaryPerMonth: undefined,
    // firstMainJobProfile: "",
    // firstWorkExpCerti: "",
    // secondOrganizationName: "",
    // secondDesignation: "",
    // secondDateOfJoining: undefined,
    // secondDateOfRelieving: undefined,
    // secondServiceLength: undefined,
    // secondSalaryPerMonth: undefined,
    // secondMainJobProfile: "",
    // secondWorkExpCerti: "",
    // thirdOrganizationName: "",
    // thirdDesignation: "",
    // thirdDateOfJoining: undefined,
    // thirdDateOfRelieving: undefined,
    // thirdServiceLength: undefined,
    // thirdSalaryPerMonth: undefined,
    // thirdMainJobProfile: "",
    // thirdWorkExpCerti: "",

    // cvFile: "",
  };

  useEffect(() => {
    dispatch(getsingleapplication({ id: id })).then((data) => {
      console.log("singleapp");
      console.log(data.payload.data.selectedDegrees);
      seteduValidation(data.payload.data.selectedDegrees);
      seteduValidationtext(data.payload.data.requreddegrees);
    });
  }, []);

  const {
    handleSubmit,
    register,
    setValue,
    setError,
    control,
    reset,
    watch,
    formState,
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(Validation),
  });
  const { isValid, dirtyFields, errors } = formState;
  const data = watch();
  const buttonSx = {
    ...{
      bgcolor: blue,
      "&:hover": {
        bgcolor: blue,
      },
    },
  };
  const [ratingValue, setRatingValue] = useState(2.5); // State for Rating
  const [feedbackText, setFeedbackText] = useState(""); // State for TextField

  const [loadingState, setLoadingState] = useState(false);

  const [clerification, setClerification] = useState(false);
  const currentYear = new Date().getFullYear();
  const comparisonDate = new Date(currentYear, 6, 1);
  // console.log("comparisonDate")
  // console.log(currentYear)
  //const comparisonDate = new Date(2025, 6, 1);
  const [EDUCounter, setEDUCounter] = useState(0);
  const [EDUDetails, setEDUDetails] = useState([]);
  const [WorkCounter, setWorkCounter] = useState(0);
  var totalworkexp = 0;
  const [WorkDetails, setWorkDetails] = useState([]);
  const [input1, setInput1] = useState("");
  const [input1Err, setInput1Err] = useState("");
  const [input2, setInput2] = useState("");
  const [input2Err, setInput2Err] = useState("");
  const [input3, setInput3] = useState("");
  const [input3Err, setInput3Err] = useState("");
  const [input4, setInput4] = useState("");
  const [input4Err, setInput4Err] = useState("");

  const [winput1, setWInput1] = useState("");
  const [winput1Err, setWInput1Err] = useState("");
  const [winput2, setWInput2] = useState("");
  const [winput2Err, setWInput2Err] = useState("");
  const [enddate, setenddate] = useState(undefined);
  const [startdate, setstartdate] = useState(undefined);
  const [winput3, setWInput3] = useState("");
  const [winput3Err, setWInput3Err] = useState("");
  const [winput4, setWInput4] = useState("");
  const [winput4Err, setWInput4Err] = useState("");
  const [winput5, setWInput5] = useState("");
  const [winput5Err, setWInput5Err] = useState("");
  const [winput6, setWInput6] = useState("");
  const [winput7, setWInput7] = useState("");
  const [winput6Err, setWInput6Err] = useState("");
  const [addWInputErr, setWinputErr] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AddEducationDetailClick = (e) => {
    if (
      input1 === "" ||
      input2 === "" ||
      input3 === "" ||
      input4 === "" ||
      cfilesud === null
    ) {
      document.getElementById("edu-details-div").innerText =
        "Enter All the Details";
      setTimeout(function () {
        document.getElementById("edu-details-div").innerText = "";
      }, 10000);
      return;
    }

    setEDUCounter(EDUCounter + 1);
    // eduupload.add(cfilesud);

    setEDUupload([...eduupload, cfilesud]);

    setEDUDetails([
      ...EDUDetails,
      {
        id: EDUDetails.length + 1,
        degree: input1,
        year: dayjs(startDate).format("MMM/YYYY"),
        university: input2,
        subject: input3,
        percentage: input4,
      },
    ]);

    setInput1("");
    setInput2("");
    setInput3("");
    setInput4("");
    setStartDate(undefined);
    setCatalogImagesbd([]);
    imageCounterbd = 0;
    setCFilesud(null);
    console.log(EDUDetails);
  };
  const handleToggle = (event) => {
    setIsExperienced(event.target.checked);
    // console.log("Switch toggled:", event.target.checked);
  };
  const handleToggle2 = (event) => {
    setisrelocated(event.target.checked);
    // console.log("Switch toggled:", event.target.checked);
  };
  const handleToggle3 = (event) => {
    setisFresher(event.target.checked);
    // console.log("Switch toggled:", event.target.checked);
  };
  const handleImageDelete = (id) => {
    // console.log(id)
    // --catalogImageCounter;
    // const remainingImage = catalogImages.filter((i) => i.id !== id);
    // console.log(remainingImage);
    setCatalogImages([]);
  };

  const handleDelete = (id) => {
    setEDUDetails(EDUDetails.filter((row) => row.id !== id));
  };
  const handleDeletework = (id) => {
    setWorkDetails(WorkDetails.filter((row) => row.id !== id));
  };
  const generateGUID = () => {
    function S4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4();
  };
  const AddWorkDetailClick = (e) => {
    if (
      winput1 === "" ||
      winput2 === "" ||
      winput3 === "" ||
      winput4 === "" ||
      winput5 === "" ||
      winput5 === "" ||
      winput6 === ""
    ) {
      document.getElementById("work-details-div").innerText =
        "Enter All the Details";
      setTimeout(function () {
        document.getElementById("work-details-div").innerText = "";
      }, 10000);
      return;
    }
    setWorkCounter(WorkCounter + 1);
    // totalworkexp=totalworkexp+parseInt(winput3);
    setWorkDetails([
      ...WorkDetails,
      {
        id: WorkDetails.length + 1,
        Institute: winput1,
        Designation: winput2,
        Joining: dayjs(startdate).format("YYYY-MM-DD"),
        Relieving:
        isFresher
            ? dayjs(id5).format("YYYY-MM-DD")
            : dayjs(enddate).format("YYYY-MM-DD"),
        isfresher: isFresher,
        Service: winput3,
        Salary: winput4,
        Profile: winput5,
        WorkExp: winput6,
        WorkExpdays: winput7,
      },
    ]);
    setisFresher(false);
    setWInput1("");
    setWInput2("");
    setWInput3("");
    setWInput4("");
    setWInput5("");
    setWInput6("");
    setWInput7("");

    // console.log(WorkDetails);
  };

  const onSubmit = (data) => {
    // {}
    console.log(eduValidation);
    console.log(EDUDetails);
    var validationcheck = 0;
    if (eduValidation && eduValidation.length > 0) {
      JSON.parse(eduValidation).map((i) => {
        EDUDetails.map((x) => {
          if (x.degree === i.label) {
            validationcheck = validationcheck + 1;
          }
        });
      });
    }

    var exyear = parseInt(
      getFormatedStringFromDays(totalServiceYears).slice(0, 2)
    );

    if (id6 != 0 && exyear < id6) {
      // setError("age", { type: "manual", message: "You are not applicable" });
      toast.error(
        "You are not applicable. " +
          id6 +
          " years of work experience is required ",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else if (data.age > id3) {
      // setError("age", { type: "manual", message: "You are not applicable" });
      toast.error("You are not applicable your age is more than " + id3, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (validationcheck != JSON.parse(eduValidation).length) {
      toast.error(eduValidationtext, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (cfilesbd == null) {
      toast.error("Any Valid Government Document File Required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (isExperienced && cfiles == null) {
      toast.error("Latest Salary slip Document File Required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (ufiles == null) {
      toast.error("Candidate Photo File Required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (cfilescv == null) {
      toast.error("Updated CV File Required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(true);

      const formData = new FormData();
      formData.append("openingid", id);
      formData.append("positioncode", id4);
      formData.append("positionName", data.positionName);
      formData.append("title", data.Title);
      formData.append("firstName", data.firstName);
      formData.append("middleName", data.middleName);
      formData.append("lastName", data.lastName);
      formData.append("mobileNo", data.mobileNo);
      formData.append("wmobileNo", data.wmobileNo);
      formData.append("emailId", data.emailId);
      formData.append("address", data.address);
      formData.append("pincode", data.currentPincode);
      formData.append("currentCity", data.currentCity);
      formData.append("currentState", data.currentState);
      formData.append("nativeState", data.nativeState);
      formData.append(
        "dateOfBirth",
        dayjs(data.dateOfBirth).format("YYYY-MM-DD")
      );
      formData.append("currentDesignation", data.currentDesignation);
      formData.append("currentOrganization", data.currentOrganization);
      formData.append("netToHandSalary", data.netToHandSalary);
      formData.append("grossSalary", data.grossSalary);
      formData.append("experience", data.experince);
      formData.append("minimumnoticePeriod", data.minimumnoticePeriod);
      formData.append("relocatelocation", isrelocated);
      formData.append("age", data.age);
      formData.append("noticeperiod", data.minimumnoticePeriod);
      formData.append("isExperienced", isExperienced);
      formData.append("educationdata", JSON.stringify(EDUDetails));
      formData.append("workdata", JSON.stringify(WorkDetails));
      formData.append("dobProofFile", cfilesbd);
      formData.append("latetsalryslip", cfiles);
      formData.append("cv", cfilescv);
      formData.append("photo", ufiles);
      formData.append("gender", data.gender);
      formData.append("dateason", id5);
      eduupload.forEach((image, index) => {
        formData.append("eduupload[]", image); // Append each file with a key of "images[]"
      });
      formData.append(
        "totalworkexp",
        getFormatedStringFromDays(totalServiceYears)
      );
      console.log(formData);
      dispatch(addapplication(formData)).then((res) => {
        console.log(res);
        setLoading(false);
        if (res.payload.status) {
          setShowPopup(true);
          const { id, name, email, phone } = res.payload.data; // Extract the required fields
          setFid(id);
          setFname(name);
          setFemail(email);
          setFphone(phone);
          console.log("ID:", id);
          console.log("Name:", name);
          console.log("Email:", email);
          console.log("Phone:", phone);
          toast.success(res.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(res.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    }
  };
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "degree", headerName: "Degree", width: 150 },
    { field: "year", headerName: "Year", width: 150 },
    { field: "university", headerName: "University", width: 200 },
    { field: "subject", headerName: "Subject", width: 150 },
    { field: "percentage", headerName: "Percentage", width: 150 },
  ];
  const columns2 = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "Institute", headerName: "Institute", width: 150 },
    { field: "Designation", headerName: "Designation", width: 150 },
    { field: "Joining", headerName: "Joining Date", width: 150 },
    { field: "Relieving", headerName: "Relieving Date", width: 150 },
    { field: "Service", headerName: "Service (Years)", width: 150 },
    { field: "Salary", headerName: "Salary", width: 150 },
    { field: "Profile", headerName: "Profile", width: 150 },
    { field: "WorkExp", headerName: "Work Experience", width: 150 },
  ];
  const totalServiceYears =
    WorkDetails &&
    WorkDetails.reduce((total, row) => total + row.WorkExpdays, 0);
  function getFormatedStringFromDays(numberOfDays) {
    var years = Math.floor(numberOfDays / 365);
    var months = Math.floor((numberOfDays % 365) / 30);
    var days = Math.floor((numberOfDays % 365) % 30);

    var yearsDisplay =
      years > 0 ? years + (years == 1 ? " year, " : " years, ") : "";
    var monthsDisplay =
      months > 0 ? months + (months == 1 ? " month, " : " months, ") : "";
    var daysDisplay = days > 0 ? days + (days == 1 ? " day" : " days") : "";
    return yearsDisplay + monthsDisplay + daysDisplay;
  }
  useEffect(() => {
    console.log(EDUDetails);
    if (data.experience == "experienced") {
      setIsExperienced(true);
      setValidation(validationSchemaexp);
    } else {
      setIsExperienced(false);
      setValidation(validationSchema);
    }
  }, [data.experience]);

  var imagepopup = Feedback;
  var titlepopup = "WE WANT YOUR FEEDBACK!";
  var despopup = "";
  var linkpopup = "";
  const [showPopup, setShowPopup] = useState(false);
  const [fid, setFid] = useState("");
  const [fname, setFname] = useState("");
  const [femail, setFemail] = useState("");
  const [fphone, setFphone] = useState("");
  const Popup = ({ onClose }) => {
    const popupRef = useRef(null);

    // useEffect(() => {
    //   // Handle clicks outside of the popup
    //   const handleClickOutside = (event) => {
    //     if (popupRef.current && !popupRef.current.contains(event.target)) {
    //       onClose();
    //     }
    //   };

    //   // Attach event listener
    //   document.addEventListener("mousedown", handleClickOutside);

    //   // Cleanup event listener on component unmount
    //   return () =>
    //     document.removeEventListener("mousedown", handleClickOutside);
    // }, [onClose]);
    return (
      <div className="popupoverlay">
        <div className="div-card-container" ref={popupRef}>
          <div>
            <img
              src={imagepopup} // Replace with your image URL
              alt="Popup"
              className="popupimage"
            />
          </div>

          {/* Text Section */}
          <div>
            <button onClick={onClose} className="close-button">
              &times;
            </button>

            <div
              className="d-flex flex-column m-20"
              style={{ margin: 20, width: "90%" }}
            >
              <h6 className="m-20">{titlepopup}</h6>
              <Stack spacing={1}>
                <Rating
                  name="half-rating"
                  size="large"
                  value={ratingValue}
                  max={10}
                  precision={0.5}
                  onChange={(event, newValue) => setRatingValue(newValue)}
                />
              </Stack>
              <div style={{ marginTop: 10, width: "100%" }}>
                <TextField
                  id="outlined-multiline-static"
                  label="Write Your Feedback"
                  multiline
                  fullWidth
                  rows={6}
                  value={feedbackText}
                  onChange={(event) => setFeedbackText(event.target.value)}
                />
              </div>
              <div style={{ width: "100%" }}>
                <Button
                  style={{ marginTop: 10, width: "100%" }}
                  variant="contained"
                  color="primary"
                  // type="submit"
                  onClick={() => sendFeedbackButton()}
                >
                  SEND FEEDBACK
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const closePopup = () => {
    setShowPopup(false);
  };
  const sendFeedbackButton = () => {
    // setShowPopup(false);sendfeedback
    setLoading(true);

    var jsonparams = {
      name: fname,
      applicationid: fid,
      email: femail,
      phone: fphone,
      description: feedbackText,
      rating: ratingValue,
    };
    dispatch(sendfeedbackcall(jsonparams)).then((res) => {
      console.log(res);
      setLoading(false);
      if (res.payload.status) {
        toast.success(res.payload.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/careers");
      } else {
        toast.success(res.payload.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };
  return (
    <>
      {showPopup && <Popup onClose={closePopup} />}
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div>
          <ToastContainer />
          <ScrollToTop />
          <BreadCrumbs
            parent="Home"
            child="Job Application"
            parentPath="/"
            imgPath={BreadCrumbImage}
          />
          <form className="m-40">
            <div className="flex justify-center  items-center">
              <div className="w-full  p-40 " style={{ margin: "100px" }}>
                <Box
                  className="form-box"
                  sx={{
                    width: "100%",
                    borderRadius: 1,
                    bgcolor: "#EEEEEE",
                    padding: "20px",
                  }}
                >
                  <div className="mt-10 mb-10">
                    <Controller
                      name="positionName"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Position Name"
                          autoFocus
                          value={value}
                          variant="outlined"
                          //  onChange={(e) => setName(e.target.value)}
                          disabled
                          name="positionName"
                          id="positionName"
                          error={!!errors.positionName}
                          helperText={errors?.positionName?.message}
                          fullWidth
                          {...register("positionName")}
                        />
                      )}
                    />
                  </div>

                  <div
                    className="d-flex flex-column mb-3"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="mb-3"
                      style={{
                        width: "100%",
                        marginTop: "20px",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      {/* Mr., Mrs., Ms., Miss, */}
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="Title"
                          control={control}
                          render={({ field }) => (
                            <FormControl
                              style={{ width: "100%" }}
                              variant="outlined"
                              error={!!errors.Title}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Select Title
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                {...field}
                                className="mb-2"
                                label="Select Title"
                                // autoFocus
                                id="Title"
                                required
                              >
                                <MenuItem value="Mr.">Mr.</MenuItem>
                                <MenuItem value="Mrs.">Mrs.</MenuItem>
                                <MenuItem value="Ms.">Ms.</MenuItem>
                                <MenuItem value="Miss.">Miss.</MenuItem>
                              </Select>
                              <FormHelperText>
                                {errors?.Title?.message}
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                        {/* <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Title
                              </InputLabel>
                              <Select
                                value={input1}
                                style={{ width: "100%" }}
                                id="demo-simple-select-label"
                                label="Title"
                                onChange={(e) => {
                                  setInput1(e.target.value);
                                  if (e.target.value != "") {
                                    setInput1Err("");
                                  } else {
                                    setInput1Err("Title is required !!!");
                                  }
                                }}
                              >
                                <MenuItem value="Mr.">Mr.</MenuItem>
                                <MenuItem value="Mrs.">Mrs.</MenuItem>
                                <MenuItem value="Ms.">Ms.</MenuItem>
                                <MenuItem value="Miss.">Miss.</MenuItem>
                                
                              </Select>
                            </FormControl> */}
                      </div>

                      <div style={{ width: "100%" }}>
                        <Controller
                          name="firstName"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="First Name"
                              autoFocus
                              value={value}
                              variant="outlined"
                              //  onChange={(e) => setName(e.target.value)}
                              required
                              name="firstName"
                              id="firstName"
                              error={!!errors.firstName}
                              helperText={errors?.firstName?.message}
                              fullWidth
                              {...register("firstName")}
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="middleName"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="Middle Name"
                              autoFocus
                              value={value}
                              variant="outlined"
                              //  onChange={(e) => setName(e.target.value)}
                              // required
                              name="middleName"
                              id="middleName"
                              error={!!errors.middleName}
                              helperText={errors?.middleName?.message}
                              fullWidth
                              {...register("middleName")}
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="lastName"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="Last Name"
                              autoFocus
                              value={value}
                              variant="outlined"
                              //  onChange={(e) => setName(e.target.value)}
                              required
                              name="lastName"
                              id="lastName"
                              error={!!errors.lastName}
                              helperText={errors?.lastName?.message}
                              fullWidth
                              {...register("lastName")}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column mb-3"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="mb-3"
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="mobileNo"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="Mobile No"
                              autoFocus
                              value={value}
                              variant="outlined"
                              required
                              name="mobileNo"
                              id="mobileNo"
                              inputProps={{
                                maxLength: 10,
                                pattern: "[0-9]*", // This allows only numeric input
                                inputMode: "numeric", // Makes the mobile number keypad appear on mobile devices
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ); // Replace any non-numeric character
                              }}
                              error={!!errors.mobileNo}
                              helperText={errors?.mobileNo?.message}
                              fullWidth
                              {...register("mobileNo")}
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="confirmMobileNo"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="Confirm Mobile No"
                              autoFocus
                              value={value}
                              variant="outlined"
                              //  onChange={(e) => setName(e.target.value)}
                              required
                              name="confirmMobileNo"
                              id="confirmMobileNo"
                              inputProps={{
                                maxLength: 10,
                                pattern: "[0-9]*", // This allows only numeric input
                                inputMode: "numeric", // Makes the mobile number keypad appear on mobile devices
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ); // Replace any non-numeric character
                              }}
                              error={!!errors.confirmMobileNo}
                              helperText={errors?.confirmMobileNo?.message}
                              fullWidth
                              {...register("confirmMobileNo")}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column mb-3"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="mb-3"
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="wmobileNo"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="Whatsapp Mobile No"
                              autoFocus
                              value={value}
                              variant="outlined"
                              required
                              name="wmobileNo"
                              id="wmobileNo"
                              inputProps={{
                                maxLength: 10,
                                pattern: "[0-9]*", // This allows only numeric input
                                inputMode: "numeric", // Makes the mobile number keypad appear on mobile devices
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ); // Replace any non-numeric character
                              }}
                              error={!!errors.wmobileNo}
                              helperText={errors?.wmobileNo?.message}
                              fullWidth
                              {...register("wmobileNo")}
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="wconfirmMobileNo"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="Confirm Whatsapp Mobile No"
                              autoFocus
                              value={value}
                              variant="outlined"
                              //  onChange={(e) => setName(e.target.value)}
                              required
                              name="wconfirmMobileNo"
                              id="wconfirmMobileNo"
                              inputProps={{
                                maxLength: 10,
                                pattern: "[0-9]*", // This allows only numeric input
                                inputMode: "numeric", // Makes the mobile number keypad appear on mobile devices
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ); // Replace any non-numeric character
                              }}
                              error={!!errors.wconfirmMobileNo}
                              helperText={errors?.wconfirmMobileNo?.message}
                              fullWidth
                              {...register("wconfirmMobileNo")}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column mb-3"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="mb-3"
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="emailId"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="Email ID"
                              autoFocus
                              value={value}
                              variant="outlined"
                              //  onChange={(e) => setName(e.target.value)}
                              required
                              name="emailId"
                              id="emailId"
                              type="email"
                              error={!!errors.emailId}
                              helperText={errors?.emailId?.message}
                              fullWidth
                              {...register("emailId")}
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="confirmEmailId"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="Confirm Email ID"
                              autoFocus
                              value={value}
                              variant="outlined"
                              //  onChange={(e) => setName(e.target.value)}
                              required
                              name="confirmEmailId"
                              id="confirmEmailId"
                              type="email"
                              error={!!errors.confirmEmailId}
                              helperText={errors?.confirmEmailId?.message}
                              fullWidth
                              {...register("confirmEmailId")}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column mb-3"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="mb-3"
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div className="d-flex flex-column">
                          <Typography sx={{ padding: "5px", fontSize: 14 }}>
                            Candidate Photo to be attached : The size of the
                            document to be suggested* (*jpeg, max 2MB)
                          </Typography>
                          <div className="flex justify-start">
                            {
                              imageCounteruser == 0 && (
                                // <Controller
                                //   name="images"
                                //   control={control}
                                //   render={({ field: { onChange, value } }) => (

                                <label htmlFor="button-file">
                                  <input
                                    accept="image/jpg,image/jpeg"
                                    className="hidden"
                                    id="button-file"
                                    type="file"
                                    onChange={async (e) => {
                                      // alert("IMgae 7")
                                      function readFileAsync() {
                                        return new Promise(
                                          (resolve, reject) => {
                                            const file4 = e.target.files[0];
                                            // Check file size
                                            if (file4 != undefined) {
                                              if (
                                                file4 &&
                                                file4.size > 3 * 1024 * 1024
                                              ) {
                                                toast.error(
                                                  "Latest Salary slip File size exceeds 2 MB",
                                                  {
                                                    position: "top-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                  }
                                                );
                                                return;
                                              } else {
                                                setUFiles(file4);

                                                const reader = new FileReader();

                                                reader.onload = () => {
                                                  resolve({
                                                    id: generateGUID(),
                                                    url: `data:${
                                                      file4.type
                                                    };base64,${btoa(
                                                      reader.result
                                                    )}`,
                                                    type: "image",
                                                  });
                                                };

                                                reader.onerror = reject;

                                                reader.readAsBinaryString(
                                                  file4
                                                );
                                              }
                                            }
                                          }
                                        );
                                      }

                                      const newImage = await readFileAsync();

                                      ++imageCounteruser;
                                      setUserImages([newImage]);
                                    }}
                                  />
                                </label>
                              )
                              //   )}
                              // />
                            }
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div
                          className="mb-3"
                          style={{
                            width: "100%",
                            marginTop: "0px",
                            display: "flex",
                            gap: "20px",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <Controller
                              name="gender"
                              control={control}
                              render={({ field }) => (
                                <FormControl
                                  component="fieldset"
                                  error={!!errors.gender}
                                >
                                  <FormLabel component="legend">
                                    Select Gender
                                  </FormLabel>
                                  <RadioGroup
                                    {...field}
                                    aria-label="gender"
                                    name="gender"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <FormControlLabel
                                      value="male"
                                      control={<Radio />}
                                      label="Male"
                                    />
                                    <FormControlLabel
                                      value="female"
                                      control={<Radio />}
                                      label="Female"
                                    />
                                    <FormControlLabel
                                      value="others"
                                      control={<Radio />}
                                      label="Others"
                                    />
                                  </RadioGroup>
                                  <FormHelperText>
                                    {errors?.gender?.message}
                                  </FormHelperText>
                                </FormControl>
                              )}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <Controller
                              name="experience"
                              control={control}
                              render={({ field }) => (
                                <FormControl
                                  component="fieldset"
                                  disabled={id6 != 0}
                                  error={!!errors.experience}
                                >
                                  <FormLabel component="legend">
                                    Experience Level
                                  </FormLabel>
                                  <RadioGroup
                                    {...field}
                                    aria-label="experience"
                                    name="experience"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <FormControlLabel
                                      value="fresher"
                                      control={<Radio />}
                                      label="Fresher"
                                    />
                                    <FormControlLabel
                                      value="experienced"
                                      control={<Radio />}
                                      label="Experienced"
                                    />
                                  </RadioGroup>
                                  <FormHelperText>
                                    {errors?.experience?.message}
                                  </FormHelperText>
                                </FormControl>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                <Box
                  className="form-box"
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    borderRadius: 1,
                    bgcolor: "#EEEEEE",
                    padding: "20px",
                  }}
                >
                  <Typography sx={{ padding: "20px", fontSize: 20 }}>
                    Address Details
                  </Typography>

                  <div
                    className="d-flex flex-column mb-3"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="mb-3"
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="address"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="Address"
                              autoFocus
                              value={value}
                              variant="outlined"
                              //  onChange={(e) => setName(e.target.value)}
                              required
                              name="address"
                              id="address"
                              error={!!errors.address}
                              helperText={errors?.address?.message}
                              fullWidth
                              {...register("address")}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column mb-3"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="mb-3"
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="currentPincode"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="Current Pincode"
                              autoFocus
                              value={value}
                              variant="outlined"
                              //  onChange={(e) => setName(e.target.value)}
                              required
                              name="currentPincode"
                              id="currentPincode"
                              inputProps={{
                                maxLength: 6,
                                pattern: "[0-9]*", // This allows only numeric input
                                inputMode: "numeric", // Makes the mobile number keypad appear on mobile devices
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ); // Replace any non-numeric character
                              }}
                              error={!!errors.currentPincode}
                              helperText={errors?.currentPincode?.message}
                              fullWidth
                              {...register("currentPincode")}
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="currentCity"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="Current City"
                              autoFocus
                              value={value}
                              variant="outlined"
                              //  onChange={(e) => setName(e.target.value)}
                              required
                              name="currentCity"
                              id="currentCity"
                              type="email"
                              error={!!errors.currentCity}
                              helperText={errors?.currentCity?.message}
                              fullWidth
                              {...register("currentCity")}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column mb-3"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="mb-3"
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="currentState"
                          control={control}
                          render={({ field }) => (
                            <FormControl
                              style={{ width: "100%" }}
                              variant="outlined"
                              error={!!errors.currentState}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Select Current State
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                {...field}
                                className="mb-2"
                                label="Select Current State"
                                // autoFocus
                                id="currentState"
                                required
                              >
                                {IndState.map((i) => (
                                  <MenuItem value={i.name}>{i.label}</MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {errors?.currentState?.message}
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                        {/* <Controller
                      name="currentState"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Current State"
                          autoFocus
                          value={value}
                          variant="outlined"
                          //  onChange={(e) => setName(e.target.value)}
                          required
                          name="currentState"
                          id="currentState"
                          type="number"
                          error={!!errors.currentState}
                          helperText={errors?.currentState?.message}
                          fullWidth
                          {...register("currentState")}
                        />
                      )}
                    /> */}
                      </div>
                      <div style={{ width: "100%" }}>
                        <Controller
                          name="nativeState"
                          control={control}
                          render={({ field }) => (
                            <FormControl
                              style={{ width: "100%" }}
                              variant="outlined"
                              error={!!errors.nativeState}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Select Native State
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                {...field}
                                className="mb-2"
                                label="Select Native State"
                                // autoFocus
                                id="nativeState"
                                required
                              >
                                {IndState.map((i) => (
                                  <MenuItem value={i.name}>{i.label}</MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {errors?.nativeState?.message}
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                        {/* <Controller
                      name="nativeState"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Native State"
                          autoFocus
                          value={value}
                          variant="outlined"
                          //  onChange={(e) => setName(e.target.value)}
                          required
                          name="nativeState"
                          id="nativeState"
                          type="email"
                          error={!!errors.nativeState}
                          helperText={errors?.nativeState?.message}
                          fullWidth
                          {...register("nativeState")}
                        />
                      )}
                    /> */}
                      </div>
                    </div>
                  </div>
                </Box>
                {isExperienced ? (
                  <>
                    <Box
                      className="form-box"
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        borderRadius: 1,
                        bgcolor: "#EEEEEE",
                        padding: "20px",
                      }}
                    >
                      <Typography sx={{ padding: "20px", fontSize: 20 }}>
                        Employee Details
                      </Typography>

                      <div
                        className="d-flex flex-column mb-3"
                        style={{ width: "100%" }}
                      >
                        <div
                          className="mb-3"
                          style={{
                            width: "100%",
                            marginTop: "0px",
                            display: "flex",
                            gap: "20px",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <Controller
                              name="currentDesignation"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  label="Current Designation"
                                  autoFocus
                                  value={value}
                                  variant="outlined"
                                  //  onChange={(e) => setName(e.target.value)}
                                  required
                                  name="currentDesignation"
                                  id="currentDesignation"
                                  error={!!errors.currentDesignation}
                                  helperText={
                                    errors?.currentDesignation?.message
                                  }
                                  fullWidth
                                  {...register("currentDesignation")}
                                />
                              )}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <Controller
                              name="currentOrganization"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  label="Current Organization"
                                  autoFocus
                                  value={value}
                                  variant="outlined"
                                  //  onChange={(e) => setName(e.target.value)}
                                  required
                                  name="currentOrganization"
                                  id="currentOrganization"
                                  error={!!errors.currentOrganization}
                                  helperText={
                                    errors?.currentOrganization?.message
                                  }
                                  fullWidth
                                  {...register("currentOrganization")}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </Box>

                    <Box
                      className="form-box"
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        borderRadius: 1,
                        bgcolor: "#EEEEEE",
                        padding: "20px",
                      }}
                    >
                      <Typography sx={{ padding: "20px", fontSize: 20 }}>
                        Salary Details (In Numbers Only)
                      </Typography>

                      <div
                        className="d-flex flex-column mb-3"
                        style={{ width: "100%" }}
                      >
                        <div
                          className="mb-3"
                          style={{
                            width: "100%",
                            marginTop: "0px",
                            display: "flex",
                            gap: "20px",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <Controller
                              name="netToHandSalary"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  label="Monthly Take Home Salary"
                                  autoFocus
                                  value={value}
                                  variant="outlined"
                                  //  onChange={(e) => setName(e.target.value)}
                                  required
                                  type="number"
                                  name="netToHandSalary"
                                  id="netToHandSalary"
                                  error={!!errors.netToHandSalary}
                                  helperText={errors?.netToHandSalary?.message}
                                  fullWidth
                                  {...register("netToHandSalary")}
                                />
                              )}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <Controller
                              name="grossSalary"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  label="Monthly Gross Salary"
                                  autoFocus
                                  value={value}
                                  variant="outlined"
                                  //  onChange={(e) => setName(e.target.value)}
                                  required
                                  type="number"
                                  name="grossSalary"
                                  id="grossSalary"
                                  error={!!errors.grossSalary}
                                  helperText={errors?.grossSalary?.message}
                                  fullWidth
                                  {...register("grossSalary")}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            marginTop: "0px",
                            display: "flex",
                            gap: "20px",
                          }}
                        >
                          <div className="d-flex flex-column">
                            <Typography sx={{ padding: "5px", fontSize: 14 }}>
                              Latest Salary slip to be attached : The size of
                              the document to be suggested* (*pdf, *jpeg, max
                              2MB)
                            </Typography>
                            <div className="flex justify-start">
                              {
                                imageCounter == 0 && (
                                  // <Controller
                                  //   name="images"
                                  //   control={control}
                                  //   render={({ field: { onChange, value } }) => (
                                  <label htmlFor="button-file">
                                    <input
                                      accept="image/jpg,image/jpeg,application/pdf"
                                      className="hidden"
                                      id="button-file"
                                      type="file"
                                      onChange={async (e) => {
                                        // alert("IMgae 7")
                                        function readFileAsync() {
                                          return new Promise(
                                            (resolve, reject) => {
                                              const file = e.target.files[0];
                                              // Check file size
                                              if (file != undefined) {
                                                if (
                                                  file &&
                                                  file.size > 3 * 1024 * 1024
                                                ) {
                                                  toast.error(
                                                    "Latest Salary slip File size exceeds 2 MB",
                                                    {
                                                      position: "top-right",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                    }
                                                  );
                                                  return;
                                                } else {
                                                  setCFiles(file);

                                                  const reader =
                                                    new FileReader();

                                                  reader.onload = () => {
                                                    resolve({
                                                      id: generateGUID(),
                                                      url: `data:${
                                                        file.type
                                                      };base64,${btoa(
                                                        reader.result
                                                      )}`,
                                                      type: "image",
                                                    });
                                                  };

                                                  reader.onerror = reject;

                                                  reader.readAsBinaryString(
                                                    file
                                                  );
                                                }
                                              }
                                            }
                                          );
                                        }

                                        const newImage = await readFileAsync();

                                        ++imageCounter;
                                        setCatalogImages([newImage]);
                                      }}
                                    />
                                  </label>
                                )
                                //   )}
                                // />
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
                <Box
                  className="form-box"
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    borderRadius: 1,
                    bgcolor: "#EEEEEE",
                    padding: "20px",
                  }}
                >
                  <Typography sx={{ padding: "20px,20px,20px,20px", fontSize: 20 }}>
                    Date of Birth (Self-Attested copy of proof of age - Any
                    Valid Government Document to be attached *pdf, "jpeg, The
                    maximum size - 2MB)
                  </Typography>
                  <Typography sx={{color: "red", padding: "2px", fontSize: 14 }}>
                   {"The Date Of Birth Must Be Later Than "+ dayjs(istartdate).format("DD/MM/YYYY")}
                  </Typography>

                  <div
                    className="d-flex flex-column mb-3"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="d-flex flex-row"
                      style={{ gap: "20px", width: "100%" }}
                    >
                      <Controller
                        control={control}
                        name="dateOfBirth"
                        render={({ field: { onChange, value } }) => (
                          <FormControl
                            className=" mt-20 w-full"
                            Variant="filled"
                            error={!!errors.dateOfBirth}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker"]}
                                autoFocus
                                className="w-full"
                              >
                                <DatePicker
                                  //  disabled={Dayplandata && Dayplandata.length > 0}
                                  //  disabled
                                  label="Select Birthdate"
                                  format="DD-MM-YYYY"
                                  className="w-full"
                                  value={value}
                                  autoFocus
                                  onChange={(newValue) => {
                                    const selectedDate = dayjs(newValue);
                                    const comparisonDayjs =
                                      dayjs(comparisonDate);

                                    const ageYears = comparisonDayjs.diff(
                                      selectedDate,
                                      "year"
                                    );
                                    const exactAgeDate = selectedDate.add(
                                      ageYears,
                                      "year"
                                    );
                                    const ageDays = comparisonDayjs.diff(
                                      exactAgeDate,
                                      "day"
                                    );

                                    console.log(
                                      `Age: ${ageYears}, Extra Days: ${ageDays}, id3: ${id3}`
                                    );
                                    setValue("age", ageYears);
  
                                    // console.log("Out");
                                    // console.log(istartdate);
                                    // console.log(selectedDate);

                                    if(dayjs(istartdate)<=selectedDate){
                                     
                                      // console.log("IN");
                                      // console.log(ageYears);
                                      // console.log(id3);
                                      // console.log(ageDays);

                                      
                                      if (
                                        ageYears > id3 ||
                                        (ageYears == id3 && ageDays > 0)
                                      ) {
                                        console.log(
                                          "Invalid age or days exceeded"
                                        );
                                        setError("age", {
                                          type: "manual",
                                          message: `Not more than ${id3} years as on ${comparisonDayjs.format(
                                            "DD-MM-YYYY"
                                          )}`,
                                        });
                                      } else {
                                        console.log("Valid age");
                                        setError("age", null);
                                      }






                                    }else{
                                      setError("age", {
                                        type: "manual",
                                        message: `Date of Birth Is Not Greater Than ${dayjs(istartdate).format("DD/MM/YYYY")}`,
                                      });
                                    }
                                    
                                    onChange(newValue);
                                    // const date = dayjs(newValue).toDate();
                                    // const age = differenceInYears(
                                    //   comparisonDate,
                                    //   date
                                    // );
                                    // const months = differenceInMonths(comparisonDate, date) % 12;
                                    // const days = differenceInDays(comparisonDate, date) % 30; // Assuming 30 days per month for simplicity

                                    // setValue("age", age);
                                    // console.log("age"+age+ "id3"+id3+"month"+months+"days"+days)
                                    // if(age == id3 && months == 0 && days > 0){
                                    //   console.log("daysnoyes")
                                    //   setError("age", { type: "manual", message: "Not more than "+id3+" years as on "+dayjs(id5).format("DD-MM-YYYY") });
                                    // }else if(age == id3 && months > 0){
                                    //   console.log("noyes")
                                    //   setError("age", { type: "manual", message: "Not more than "+id3+" years as on "+dayjs(id5).format("DD-MM-YYYY") });
                                    // } else if (age > id3) {
                                    // // if (age > id3 ) {
                                    //   console.log("yes")
                                    //   setError("age", { type: "manual", message: "Not more than "+id3+" years as on "+dayjs(id5).format("DD-MM-YYYY") });
                                    // } else {
                                    //   console.log("no")
                                    //   setError("age", null);
                                    // }
                                    // onChange(newValue);
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            <FormHelperText>
                              {errors?.dateOfBirth?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                      <Controller
                        name="age"
                        control={control}
                        render={({ field: { value } }) => (
                          <TextField
                            label="Age Auto Calculate"
                            disabled
                            style={{ marginTop: 8 }}
                            value={value || ""}
                            variant="outlined"
                            fullWidth
                            {...register("age")}
                            error={!!errors.age}
                            helperText={errors?.age?.message}
                          />
                        )}
                      />
                    </div>

                    <div className="flex justify-start">
                      {
                        imageCounterbd == 0 && (
                          // <Controller
                          //   name="images"
                          //   control={control}
                          //   render={({ field: { onChange, value } }) => (
                          <label htmlFor="button-filebdt">
                            <input
                              accept="image/jpg,image/jpeg,application/pdf"
                              className="hidden"
                              id="button-filebdt"
                              type="file"
                              onChange={async (e) => {
                                // alert("IMgae 7")
                                function readFileAsync() {
                                  return new Promise((resolve, reject) => {
                                    const file1 = e.target.files[0];
                                    if (file1 != undefined) {
                                      if (
                                        file1 &&
                                        file1.size > 3 * 1024 * 1024
                                      ) {
                                        toast.error(
                                          "Any Valid Government Document File size exceeds 2 MB",
                                          {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                          }
                                        );
                                        // alert("File size exceeds 2 MB");
                                        return;
                                      } else {
                                        // console.log(file1)
                                        // console.log(file1.name)
                                        setCFilesbd(file1);
                                        const reader = new FileReader();

                                        reader.onload = () => {
                                          resolve({
                                            id: generateGUID(),
                                            url: `data:${
                                              file1.type
                                            };base64,${btoa(reader.result)}`,
                                            type: "image",
                                          });
                                        };

                                        reader.onerror = reject;

                                        reader.readAsBinaryString(file1);
                                      }
                                    }
                                  });
                                }

                                const newImage = await readFileAsync();

                                ++imageCounterbd;
                                setCatalogImagesbd([newImage]);
                              }}
                            />
                          </label>
                        )
                        //   )}
                        // />
                      }
                    </div>
                  </div>
                </Box>

                <div
                  className="d-flex flex-column mb-3"
                  style={{ display: "flex", width: "100%" }}
                >
                  <Box
                    className="form-box"
                    sx={{
                      marginTop: "20px",
                      width: "100%",
                      borderRadius: 1,
                      bgcolor: "#EEEEEE",
                      marginRight: "10px",
                      paddingLeft: "10px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ width: "98%" }}
                    >
                      <div
                        className="d-flex flex-column mb-3"
                        style={{ display: "flex", width: "100%" }}
                      >
                        <Typography
                          sx={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingTop: "20px",
                            fontSize: 20,
                          }}
                        >
                          Educational Qualifications (in chronological order
                          beginning from HSC (12th Onwards))
                        </Typography>
                        <Typography
                          sx={{
                            paddingLeft: "20px",
                            fontSize: 14,
                            color: "red",
                          }}
                        >
                          {eduValidationtext}
                        </Typography>
                      </div>
                      <div id="edu-details-div" className="text-red"></div>
                      <div
                        className="d-flex flex-column mb-3"
                        style={{ width: "100%" }}
                      >
                        <div
                          className="mb-3"
                          style={{
                            width: "100%",
                            marginTop: "0px",
                            display: "flex",
                            gap: "20px",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Degree
                              </InputLabel>
                              <Select
                                value={input1}
                                style={{ width: "100%" }}
                                id="demo-simple-select-label"
                                label="Degree"
                                onChange={(e) => {
                                  setInput1(e.target.value);
                                  if (e.target.value != "") {
                                    setInput1Err("");
                                  } else {
                                    setInput1Err("Degree is required !!!");
                                  }
                                }}
                              >
                                <MenuItem value="SSC">SSC</MenuItem>
                                <MenuItem value="HSC">HSC</MenuItem>
                                <MenuItem value="ITI">ITI</MenuItem>
                                <MenuItem value="Diploma">Diploma</MenuItem>
                                <MenuItem value="Graduation">
                                  Graduation
                                </MenuItem>
                                <MenuItem value="Post Graduation">
                                  {" "}
                                  Post Graduation
                                </MenuItem>
                                <MenuItem value="Doctoral">Doctoral</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div style={{ width: "100%" }}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale="en"
                            >
                              <DatePicker
                                className="w-full"
                                label="Completed Year"
                                views={["year", "month"]}
                                selected={startDate}
                                onChange={(date) => {
                                  setStartDate(date);
                                }}
                                id="hscCompletedYear"
                                variant="outlined"
                              />
                            </LocalizationProvider>
                          </div>
                        </div>

                        <div
                          className="mb-3"
                          style={{
                            width: "100%",
                            marginTop: "0px",
                            display: "flex",
                            marginTop: "10px",
                            gap: "20px",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <TextField
                              autoFocus
                              label="Board / University"
                              variant="outlined"
                              fullWidth
                              value={input2}
                              onChange={(e) => {
                                setInput2(e.target.value);
                                if (e.target.value != "") {
                                  setInput2Err("");
                                } else {
                                  setInput2Err(
                                    "Board/University is required !!!"
                                  );
                                }
                              }}
                            />
                            <span className="text-red mt-10 font-semibold">
                              {input2Err}
                            </span>
                          </div>
                        </div>
                        <div
                          className="mb-3"
                          style={{
                            width: "100%",
                            marginTop: "0px",
                            display: "flex",
                            gap: "20px",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <TextField
                              autoFocus
                              label="Subjects / Specialization"
                              variant="outlined"
                              fullWidth
                              value={input3}
                              onChange={(e) => {
                                setInput3(e.target.value);
                                if (e.target.value != "") {
                                  setInput3Err("");
                                } else {
                                  setInput3Err(
                                    "Subjects / Specialization is required !!!"
                                  );
                                }
                              }}
                            />
                            <span className="text-red mt-10 font-semibold">
                              {input3Err}
                            </span>
                          </div>
                        </div>

                        <div
                          className="mb-3"
                          style={{
                            width: "100%",
                            marginTop: "0px",
                            display: "flex",
                            gap: "20px",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <TextField
                              autoFocus
                              label="Percentage / Percentile"
                              variant="outlined"
                              fullWidth
                              type="number"
                              value={input4}
                              onChange={(e) => {
                                setInput4(e.target.value);
                                if (e.target.value != "") {
                                  setInput4Err("");
                                } else {
                                  setInput4Err(
                                    "Percentage / Percentile is required !!!"
                                  );
                                }
                              }}
                            />
                            <span className="text-red mt-10 font-semibold">
                              {input4Err}
                            </span>
                          </div>
                        </div>

                        <div
                          className="d-flex flex-column"
                          style={{ display: "flex", gap: 10, width: "100%" }}
                        >
                          <Typography
                            sx={{
                              paddingLeft: "20px",
                              width: "100%",
                              fontSize: 14,
                            }}
                          >
                            Upload Document * (Document - Word or PDF file only
                            and 2 MB File Size)
                          </Typography>

                          <div
                            style={{ marginLeft: "20px" }}
                            className="flex justify-start"
                          >
                            {
                              imageCounterud == 0 && (
                                // <Controller
                                //   name="images"
                                //   control={control}
                                //   render={({ field: { onChange, value } }) => (
                                <label htmlFor="button-fileud">
                                  <input
                                    accept="image/jpg,image/jpeg,application/pdf"
                                    className="hidden"
                                    id="button-fileud"
                                    type="file"
                                    onChange={async (e) => {
                                      // alert("IMgae 7")
                                      function readFileAsync() {
                                        return new Promise(
                                          (resolve, reject) => {
                                            const file2 = e.target.files[0];
                                            if (file2 != undefined) {
                                              if (
                                                file2.size >
                                                3 * 1024 * 1024
                                              ) {
                                                toast.error(
                                                  "Document File size exceeds 2 MB",
                                                  {
                                                    position: "top-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                  }
                                                );
                                                return;
                                              } else {
                                                // console.log(file2)
                                                // console.log(file2.name)
                                                setCFilesud(file2);
                                                const reader = new FileReader();

                                                reader.onload = () => {
                                                  resolve({
                                                    id: generateGUID(),
                                                    url: `data:${
                                                      file2.type
                                                    };base64,${btoa(
                                                      reader.result
                                                    )}`,
                                                    type: "image",
                                                  });
                                                };

                                                reader.onerror = reject;

                                                reader.readAsBinaryString(
                                                  file2
                                                );
                                              }
                                            }
                                          }
                                        );
                                      }

                                      const newImage = await readFileAsync();

                                      ++imageCounterud;
                                      setCatalogImagesud([newImage]);
                                    }}
                                  />
                                </label>
                              )
                              //   )}
                              // />
                            }
                          </div>
                        </div>

                        <div
                          className="mb-3 mt-3"
                          style={{
                            width: "100%",
                            display: "flex",
                            gap: "20px",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <Button
                              style={{ width: "100%" }}
                              variant="contained"
                              color="primary"
                              // type="submit"
                              onClick={AddEducationDetailClick}
                            >
                              Add Education Detail
                            </Button>
                          </div>
                        </div>

                        <div
                          className="mb-3"
                          style={{
                            width: "100%",
                            marginTop: "0px",
                            display: "flex",
                          }}
                        >
                          <div className="d-flex flex-column mb-3">
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Degree</TableCell>
                                    <TableCell>Year</TableCell>
                                    <TableCell>University</TableCell>
                                    <TableCell>Subject</TableCell>
                                    <TableCell>
                                      Percentage / Percentile
                                    </TableCell>
                                    <TableCell>Document</TableCell>
                                    <TableCell>Actions</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {EDUDetails.map((row, index) => (
                                    <TableRow key={row.id}>
                                      <TableCell>{row.degree}</TableCell>
                                      <TableCell>{row.year}</TableCell>
                                      <TableCell>{row.university}</TableCell>
                                      <TableCell>{row.subject}</TableCell>
                                      <TableCell>{row.percentage}</TableCell>
                                      <TableCell>
                                        {eduupload[index].name}
                                      </TableCell>
                                      <TableCell>
                                        <Button
                                          variant="contained"
                                          color="secondary"
                                          onClick={() => handleDelete(row.id)}
                                        >
                                          Delete
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>

                <div
                  className="d-flex flex-column mb-3"
                  style={{ display: "flex", width: "100%" }}
                >
                  {isExperienced ? (
                    <Box
                      className="form-box"
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        borderRadius: 1,
                        bgcolor: "#EEEEEE",
                        marginLeft: "10px",
                        paddingRight: "20px",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      <div
                        className="d-flex flex-column"
                        style={{ width: "98%" }}
                      >
                        <div
                          className="d-flex flex-row"
                          style={{ display: "flex", width: "100%" }}
                        >
                          <Typography
                            sx={{
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              paddingTop: "20px",
                              width: "60%",
                              fontSize: 20,
                            }}
                          >
                            Work Experience, if applicable (In descending order)
                          </Typography>
                        </div>
                        <Typography sx={{ paddingLeft: "20px", fontSize: 14 }}>
                          Work Experience in descending order, to start with
                          current employment
                        </Typography>
                        {id6 != 0 ? (
                          <Typography
                            sx={{
                              paddingLeft: "20px",
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            {id6 + " years of work experience is required"}
                          </Typography>
                        ) : (
                          <></>
                        )}

                        <div
                          className="d-flex flex-column mb-3"
                          style={{
                            display: "flex",
                            marginLeft: "10px",
                            marginTop: "15px",

                            width: "100%",
                          }}
                        >
                          <div id="work-details-div" className="text-red"></div>
                          <div
                            className="d-flex flex-column mb-3"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="mb-3"
                              style={{
                                width: "100%",
                                marginTop: "0px",
                                display: "flex",
                                gap: "20px",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <TextField
                                  autoFocus
                                  label="Name of the Institute/Organization"
                                  variant="outlined"
                                  fullWidth
                                  value={winput1}
                                  onChange={(e) => {
                                    setWInput1(e.target.value);
                                    if (e.target.value != "") {
                                      setWInput1Err("");
                                    } else {
                                      setWInput1Err(
                                        "Institute/Organization is required !!!"
                                      );
                                    }
                                  }}
                                />
                                <span className="text-red mt-10 font-semibold">
                                  {winput1Err}
                                </span>
                              </div>
                              <div style={{ width: "100%" }}>
                                <TextField
                                  autoFocus
                                  label="Designation"
                                  variant="outlined"
                                  fullWidth
                                  value={winput2}
                                  onChange={(e) => {
                                    setWInput2(e.target.value);
                                    if (e.target.value != "") {
                                      setWInput2Err("");
                                    } else {
                                      setWInput2Err(
                                        "Designation is required !!!"
                                      );
                                    }
                                  }}
                                />
                                <span className="text-red mt-10 font-semibold">
                                  {winput2Err}
                                </span>
                              </div>
                            </div>

                            <div
                              className="mb-3"
                              style={{
                                width: "100%",
                                marginTop: "0px",
                                display: "flex",
                                gap: "20px",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={["DatePicker"]}
                                    className=" w-full"
                                  >
                                    <DatePicker
                                      label="Date of Joining"
                                      format="DD-MM-YYYY"
                                      className="w-full"
                                      value={startdate}
                                      onChange={(newValue) => {
                                        // setstartdate(newValue);

                                        const date = dayjs(enddate).toDate();
                                        const date2 = dayjs(newValue).toDate();
                                        const years = differenceInYears(
                                          date,
                                          date2
                                        );
                                        const months =
                                          differenceInMonths(date, date2) % 12;
                                        const days = differenceInDays(
                                          date,
                                          dayjs(date2)
                                            .add(years, "years")
                                            .add(months, "months")
                                            .toDate()
                                        );
                                        const days2 = differenceInDays(
                                          date,
                                          date2
                                        );
                                        const difference = `${years} years, ${months} months, ${days} days`;
                                        setWInput7(days2);
                                        setWInput3(difference);
                                        setstartdate(newValue);
                                      }}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                                <FormHelperText>
                                  {startdate == ""
                                    ? "Please Enter Date of Joining"
                                    : ""}
                                </FormHelperText>
                              </div>

                              <div
                                className="mb-3"
                                style={{
                                  width: "100%",
                                  marginTop: "0px",
                                  display: "flex",
                                  gap: "20px",
                                }}
                              >
                                <div style={{ width: "100%" }}>
                                  <div
                                    className="d-flex flex-column"
                                    style={{ display: "flex", width: "100%" }}
                                  >
                                    <Typography
                                      sx={{
                                        paddingLeft: "20px",
                                        width: "100%",
                                        fontSize: 14,
                                      }}
                                    >
                                      Are You Currently Working ?
                                    </Typography>

                                    <Box
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        paddingLeft: "20px",
                                        marginTop: "15px",

                                        height: "40px",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 14,
                                          paddingRight: "30px",
                                        }}
                                      >
                                        Yes
                                      </Typography>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={
                                              WorkDetails.length > 0
                                                ? false
                                                : isFresher
                                            }
                                            disabled={WorkDetails.length > 0}
                                            onChange={handleToggle3}
                                            name="checkedA"
                                          />
                                        }
                                        label={""}
                                      />

                                      {/* <Typography sx={{ fontSize: 14, paddingLeft: "10px" }}>
                        Yes
                      </Typography> */}
                                    </Box>
                                  </div>
                                </div>
                                {!isFresher ? (
                                  <div style={{ width: "100%" }}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                        className=" w-full"
                                      >
                                        <DatePicker
                                          label="Date of Relieving"
                                          format="DD-MM-YYYY"
                                          className=" w-full"
                                          value={enddate}
                                          onChange={(newValue) => {
                                            const date =
                                              dayjs(newValue).toDate();
                                            const date2 =
                                              dayjs(startdate).toDate();
                                            const years = differenceInYears(
                                              date,
                                              date2
                                            );
                                            const months =
                                              differenceInMonths(date, date2) %
                                              12;
                                            const days = differenceInDays(
                                              date,
                                              dayjs(date2)
                                                .add(years, "years")
                                                .add(months, "months")
                                                .toDate()
                                            );
                                            const days2 = differenceInDays(
                                              date,
                                              date2
                                            );
                                            const difference = `${years} years, ${months} months, ${days} days`;
                                            setWInput7(days2);
                                            setWInput3(difference);
                                            setenddate(newValue);
                                          }}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                    <FormHelperText>
                                      {enddate == ""
                                        ? "Please Enter Date of Relieving"
                                        : ""}
                                    </FormHelperText>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>

                            <div
                              className="mb-3"
                              style={{
                                width: "100%",
                                marginTop: "0px",
                                display: "flex",
                                gap: "20px",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <TextField
                                  label="Total Length of Service"
                                  variant="outlined"
                                  fullWidth
                                  disabled
                                  value={winput3}
                                  onChange={(e) => {
                                    setWInput3(e.target.value.slice(0, 10));
                                    if (e.target.value == "") {
                                      setWInput3Err(
                                        "Length of Service is required !!!"
                                      );
                                    } else {
                                      setWInput3Err("");
                                    }
                                  }}
                                  //onChange={handleText2}
                                />
                                <span className="text-red mt-10 font-semibold">
                                  {winput3Err}
                                </span>
                              </div>
                              <div style={{ width: "100%" }}>
                                <TextField
                                  label="Salary Per Month"
                                  variant="outlined"
                                  fullWidth
                                  value={winput4}
                                  type="number"
                                  maxLength={"10"}
                                  inputProps={{ maxLength: 10 }}
                                  onChange={(e) => {
                                    setWInput4(e.target.value.slice(0, 10));
                                    if (e.target.value == "") {
                                      setWInput4Err("Salary is required !!!");
                                    } else {
                                      setWInput4Err("");
                                    }
                                  }}
                                  //onChange={handleText2}
                                />
                                <span className="text-red mt-10 font-semibold">
                                  {winput4Err}
                                </span>
                              </div>
                            </div>

                            <div
                              className="mb-3"
                              style={{
                                width: "100%",
                                marginTop: "0px",
                                display: "flex",
                                gap: "20px",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <TextField
                                  autoFocus
                                  label="Main Job Profile"
                                  variant="outlined"
                                  fullWidth
                                  value={winput5}
                                  onChange={(e) => {
                                    setWInput5(e.target.value);
                                    if (e.target.value != "") {
                                      setWInput5Err("");
                                    } else {
                                      setWInput5Err(
                                        "Job Profile is required !!!"
                                      );
                                    }
                                  }}
                                />
                                <span className="text-red mt-10 font-semibold">
                                  {winput5Err}
                                </span>
                              </div>
                              <div style={{ width: "100%" }}>
                                <FormControl className=" w-full">
                                  <InputLabel id="demo-simple-select-label">
                                    Work Experience Certificate is Available?
                                  </InputLabel>
                                  <Select
                                    value={winput6}
                                    labelId="demo-simple-select-label"
                                    label="Work Experience Certificate is Available?"
                                    onChange={(e) => {
                                      setWInput6(e.target.value);
                                      if (e.target.value != "") {
                                        setWInput6Err("");
                                      } else {
                                        setWInput6Err(
                                          "Experience Certificate is required !!!"
                                        );
                                      }
                                    }}
                                  >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </div>

                            <div
                              className=""
                              style={{
                                width: "100%",
                                marginTop: "0px",
                                display: "flex",
                                gap: "20px",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <Button
                                  style={{ width: "100%" }}
                                  variant="contained"
                                  color="primary"
                                  // type="submit"
                                  onClick={AddWorkDetailClick}
                                >
                                  Add Work Detail
                                </Button>
                              </div>
                            </div>
                          </div>

                          <div
                            className="mb-3"
                            style={{
                              width: "100%",
                              marginTop: "0px",
                              display: "flex",
                            }}
                          >
                            <div
                              className="d-flex flex-column mb-3"
                              style={{ width: "100%" }}
                            >
                              <TableContainer component={Paper}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Institute</TableCell>
                                      <TableCell>Designation</TableCell>
                                      <TableCell>Joining Date</TableCell>
                                      <TableCell>Relieving Date</TableCell>
                                      <TableCell>Service Years</TableCell>
                                      <TableCell>Salary</TableCell>
                                      <TableCell>Profile</TableCell>
                                      <TableCell>Work Experience</TableCell>
                                      <TableCell>Actions</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {WorkDetails.map((row) => (
                                      <TableRow key={row.id}>
                                        <TableCell>{row.Institute}</TableCell>
                                        <TableCell>{row.Designation}</TableCell>
                                        <TableCell>{row.Joining}</TableCell>
                                        <TableCell>
                                          {row.isfresher
                                            ? "Currently Working"
                                            : row.Relieving}
                                        </TableCell>
                                        <TableCell>{row.Service}</TableCell>
                                        <TableCell>{row.Salary}</TableCell>
                                        <TableCell>{row.Profile}</TableCell>
                                        <TableCell>{row.WorkExp}</TableCell>
                                        <TableCell>
                                          <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() =>
                                              handleDeletework(row.id)
                                            }
                                          >
                                            Delete
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                    <TableRow>
                                      <TableCell colSpan={4} />
                                      <TableCell>
                                        <strong>Total Service Years :</strong>
                                      </TableCell>
                                      <TableCell>
                                        <strong>
                                          {getFormatedStringFromDays(
                                            totalServiceYears
                                          )}
                                        </strong>
                                      </TableCell>
                                      <TableCell colSpan={4} />
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Box>
                  ) : (
                    <></>
                  )}
                </div>

                <Box
                  className="form-box"
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    borderRadius: 1,
                    bgcolor: "#EEEEEE",
                    padding: "20px",
                  }}
                >
                  <div
                    className="d-flex flex-column mb-3"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <Typography sx={{ padding: "20px", fontSize: 20 }}>
                      Other Details
                    </Typography>
                    <div
                      className="d-flex flex-row mb-3"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="d-flex flex-column"
                        style={{ display: "flex", gap: 10, width: "100%" }}
                      >
                        <Typography
                          sx={{
                            paddingLeft: "20px",
                            width: "100%",
                            fontSize: 14,
                          }}
                        >
                          Minimum Notice Period Required to Join in case of
                          selection
                        </Typography>

                        <div style={{ marginLeft: "20px", width: "90%" }}>
                          <Controller
                            name="minimumnoticePeriod"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                label="Enter Days"
                                autoFocus
                                value={value}
                                variant="outlined"
                                //  onChange={(e) => setName(e.target.value)}
                                required
                                name="minimumnoticePeriod"
                                id="minimumnoticePeriod"
                                error={!!errors.minimumnoticePeriod}
                                helperText={
                                  errors?.minimumnoticePeriod?.message
                                }
                                fullWidth
                                {...register("minimumnoticePeriod")}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex flex-column"
                        style={{ display: "flex", width: "100%" }}
                      >
                        <Typography
                          sx={{
                            paddingLeft: "20px",
                            width: "100%",
                            fontSize: 14,
                          }}
                        >
                          Are you ready to relocate to any location in India?
                        </Typography>

                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "20px",
                            marginTop: "15px",

                            height: "40px",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: 14, paddingRight: "30px" }}
                          >
                            Yes
                          </Typography>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={isrelocated}
                                onChange={handleToggle2}
                                name="checkedA"
                              />
                            }
                            label={""}
                          />

                          {/* <Typography sx={{ fontSize: 14, paddingLeft: "10px" }}>
                        Yes
                      </Typography> */}
                        </Box>
                      </div>

                      <div
                        className="d-flex flex-column"
                        style={{ display: "flex", gap: 10, width: "100%" }}
                      >
                        <Typography
                          sx={{
                            paddingLeft: "20px",
                            width: "100%",
                            fontSize: 14,
                          }}
                        >
                          Upload Resume / CV * (UPDATED CV - Word or PDF file
                          only and 2 MB File Size)
                        </Typography>

                        <div
                          style={{ marginLeft: "20px" }}
                          className="flex justify-start"
                        >
                          {
                            imageCountercv == 0 && (
                              // <Controller
                              //   name="images"
                              //   control={control}
                              //   render={({ field: { onChange, value } }) => (
                              <label htmlFor="button-filecv">
                                <input
                                  accept="image/jpg,image/jpeg,application/pdf"
                                  className="hidden"
                                  id="button-filecv"
                                  type="file"
                                  onChange={async (e) => {
                                    // alert("IMgae 7")
                                    function readFileAsync() {
                                      return new Promise((resolve, reject) => {
                                        const file2 = e.target.files[0];
                                        if (file2 != undefined) {
                                          if (file2.size > 3 * 1024 * 1024) {
                                            toast.error(
                                              "Updated CV File size exceeds 2 MB",
                                              {
                                                position: "top-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                              }
                                            );
                                            return;
                                          } else {
                                            // console.log(file2)
                                            // console.log(file2.name)
                                            setCFilescv(file2);
                                            const reader = new FileReader();

                                            reader.onload = () => {
                                              resolve({
                                                id: generateGUID(),
                                                url: `data:${
                                                  file2.type
                                                };base64,${btoa(
                                                  reader.result
                                                )}`,
                                                type: "image",
                                              });
                                            };

                                            reader.onerror = reject;

                                            reader.readAsBinaryString(file2);
                                          }
                                        }
                                      });
                                    }

                                    const newImage = await readFileAsync();

                                    ++imageCountercv;
                                    setCatalogImagescv([newImage]);
                                  }}
                                />
                              </label>
                            )
                            //   )}
                            // />
                          }
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex align-center mb-3"
                      style={{ justifyContent: "flex-start" }}
                    >
                      <Controller
                        name="clerification"
                        control={control}
                        render={({ field }) => (
                          <FormGroup
                            {...field}
                            style={{ width: "fit-content" }}
                          >
                            <FormControlLabel
                              required
                              control={
                                <Checkbox
                                  id="clerification"
                                  checked={clerification}
                                  onChange={(value) => {
                                    setClerification(value.target.checked);
                                  }}
                                />
                              }
                            />
                          </FormGroup>
                        )}
                      />
                      <div className=" form2-pr form2-declare-styling">
                        I hereby certify that the above information are true and
                        correct to the best of my knowledge and in case of any
                        discrepancy, the application will be disqualified.{" "}
                      </div>
                    </div>
                  </div>
                </Box>

                <div
                  className="flex mb-10"
                  style={{
                    justifyContent: "flex-start",
                    marginBottom: "20px",
                    marginTop: "20px",
                    gap: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={buttonSx}
                    disabled={loadingState || !clerification}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </Button>
                  {loadingState && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: blue[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AddNewJobApplication;
