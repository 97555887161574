import React, { useEffect, useRef, useState } from 'react'
import "./NewTechnologyManagement.css"
import './NewOtherDept.css'
import { Link, useLocation, useParams } from 'react-router-dom';
import ContactUsDepartment from '../ContactUsDepartment';
// import HomeNotices from '../../Home/HomeNotices/HomeNotices';
import HomeNotices from '../../Home2/HomeNotices/HomeNotices'
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import breadcrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/PM.jpg'
import ScrollToTop from '../../ScrollToTop';
import layer from '../../../Images_1/Departments/Images/pmdmain.png'
import NewDepartmentTeam from '../NewDepartmentTeam/NewDepartmentTeam'
import ServiceImage1 from '../../../Images_1/Departments/DepartmentHomeImages/NewTechManagement.jpg'
import ServiceImage2 from '../../../Images_1/Departments/DepartmentHomeImages/RenewableManagement.jpg'
import ServiceImage3 from '../../../Images_1/Departments/DepartmentHomeImages/PetroluemManagement.jpg'
import ServiceImage4 from '../../../Images_1/Departments/DepartmentHomeImages/EnvironmentManagement.jpg'
import ServiceImage5 from '../../../Images_1/Departments/DepartmentHomeImages/PetroleumResearchManagement.jpg'
import NewSuccessStories from './NewSuccessStories/NewSuccessStories';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import HomeCounterNew from '../../Home2/HomeCounterNew/HomeCounterNew';
import OurDepartmentTeam from '../OurDepartmentTeam';
import pageData from "./json/PetroluemDepartment.json"
import ComingSoonTeam from '../ComingSoonTeam';
import OnScrollAnimation from '../../OnScrollAnimation';
import HomeNoticesDepartment from '../../Home2/HomeNotices/HomeNoticesDepartment';
import ntm from '../../../Images_1/Departments/DepartmentHomeImages/NTM.png'
import rm from '../../../Images_1/Departments/DepartmentHomeImages/RM.png'
import em from '../../../Images_1/Departments/DepartmentHomeImages/EM.png'
import prm from '../../../Images_1/Departments/DepartmentHomeImages/PRM.png'
import { aboutdetail } from './store/aboutdetailSlice';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

const RenewableManagement = () => {
    const { id } = useParams();
    const { id2 } = useParams();
    const containerWrapperRef = useRef(null);
    const topWrapperRef = useRef(null);
    const PMS = useRef([]);
    const PMS1 = useRef([]);
    const PMS2 = useRef([]);
    const [aboutdata, setAboutdata] = useState("");
    const [abouttitle, setAbouttitle] = useState("");
    const dispatch=useDispatch();
    useEffect(() => {

        if (id == 5) {
            // if (topWrapperRef.current) {
            //     // Scroll the div into view
            //     topWrapperRef.current.scrollIntoView({ behavior: 'smooth' });

            //     // Set focus on the div
            //     topWrapperRef.current.focus();
            //   }
        } else {
            if (id == 1) {


                var index3 = pageData["services"].research.findIndex(
                    (x) => x.id === id2
                );
                // console.log(index3 + "index")
                if (index3 !== null && PMS1.current[index3]) {



                    PMS1.current[index3].scrollIntoView({ behavior: 'smooth' });
                    PMS1.current[index3].focus();
                }

            } else if (id == 2) {


                var index3 = pageData["services"].training.findIndex(
                    (x) => x.id === id2
                );
                if (index3 !== null && PMS2.current[index3]) {
                    PMS2.current[index3].scrollIntoView({ behavior: 'smooth' });
                    PMS2.current[index3].focus();
                }

            } else {
                // console.log(pageData["services"].advisory)
                var index3 = pageData["services"].advisory.findIndex(
                    (x) => x.id == id2
                );
                // console.log(index3 + "index" + id2)
                if (index3 !== null && PMS.current[index3]) {
                    PMS.current[index3].scrollIntoView({ behavior: 'smooth' });
                    PMS.current[index3].focus();
                }
            }
            // if (containerWrapperRef.current) {
            //   // Scroll the div into view
            //   containerWrapperRef.current.scrollIntoView({ behavior: 'smooth' });

            //   // Set focus on the div
            //   containerWrapperRef.current.focus();
            // }
        }

        var jsonparams = {
            "modulename": "PM",
        }
        dispatch(aboutdetail(jsonparams)).then((data) => {
            console.log(data.payload);
            if(data.payload.status=="1"||data.payload.status==1){
            setAbouttitle(data.payload.title);
            setAboutdata(data.payload.description)  
            }    
        });
    }, []);
    // console.log(id);

    const state = useLocation()
    const [SelectedService, setSelectedService] = useState(['', '', '']);
    const [selectedDiv, setSelectedDiv] = useState(id == 1 ? "R&D" : id == 2 ? "T&SD" : 'A&C');
    const activeBtnStyle = {
        // backgroundColor: '#d2611c',
        backgroundColor: '#7cba27',
        color: '#ffffff',
        transition: '0.4s ease-in-out',
    }

    return (
        <div>
            <Helmet>
      <title>
      Seismic Data Processing &amp; Interpretation services in Gujarat | Petroleum Management
      </title>
      <meta name="keywords" content="Seismic Data Processing &amp; Interpretation services in Gujarat, Seismic Data
Interpretation, OALP DSF Block Due Diligence, Reservoir Characterization, Oil and Gas
Reserve Certification, Geothermal Exploration and Development, Site Restoration Fund
Estimation, Seismic Data Processing, Seismic Inversion Analysis"/>
<meta name="description" content="We provide you Seismic Data Processing &amp; Interpretation services to make informed
decisions on hydrocarbon exploration, utilizing cutting-edge technology for precise subsurface
mapping."/>
    </Helmet>
            {/* <ScrollToTop /> */}
            {/* <DepartmentHeader styles="header-transparent header-style-2" searchDisable buttonStyle="bg-color-white" /> */}
            <div ref={topWrapperRef} className='breadcrumb-margin'>
                <BreadCrumbs className="breadcrumb-text-class" parent="Departments" child="Petroleum Management" parentPath="/departments" imgPath={breadcrumbImage} />
            </div>
            <div className='new-tech-whole-section dep-sec-bg'>
                <section className='new-technology-department-about-sectionpm'>

                    <img className="new-department-inner-image new-tech-image-translateCustomSlider3" src={layer} />
                    <div className='new-tech-title-spacing'>
                        <h1 className='new-tech-custom-slider-changing-text  new-tech-image-translateCustomSlider heading-fonts'>{abouttitle==""?"Petroleum Management":abouttitle}</h1>

                        <h6 className='new-tech-custom-slider-changing-paragraph normal-text new-tech-image-translateCustomSlider1' style={{ textAlign: "justify" }}>
                            {aboutdata==""?
                            <span>
                            The Petroleum Management Department (PMD) of GERMI specializes in Geological & Geophysical Data Interpretation and provides Research, Consultancy, and Training services to India's Hydrocarbon Exploration, Development, and Production companies. Equipped with cutting-edge technology, the PMD adheres to international standards in analyzing the data.
                            <br />During the last seven years, the department has provided consultancy services to National and International Oil and Gas Exploration companies for Reservoir Characterization, Reservoir Simulation, Reserves estimation, and Techno-Commercial evaluations of fields. Today the department is one of the pioneer service providers in the field of G&G data Evaluation in the country.
                            </span>:aboutdata}
                            </h6>
                    </div>
                </section>
            </div>

            <section className='dep-sec-bg'>
                <HomeCounterNew />
            </section>
            <section className='dep-sec-bg'>
                {/* <div className="heading-container">
                    <h4>Our Services</h4>
                    <h4 id="title1" className="title-animation-about-us">Services We Provide</h4>
                    <div className=" div-for-hr-animation"><hr id="hr1" className="animate-hr-tag-about-us" /><hr id="hr2" className="animate-hr-tag-about-us" />
                    </div>
                </div> */}
                <OnScrollAnimation title1="pm1" title2="pm2" hr1="pm3" hr2="pm4" heading="Services We Provide" subheading="" />
                <div className='top-container'>
                    <div ref={containerWrapperRef} tabIndex={"-1"} className='service-tab-container'>
                        <Link className='service-button twenty-sixteen-fonts one' onClick={() => { setSelectedDiv('A&C') }} style={selectedDiv == 'A&C' ? activeBtnStyle : {}}>Advisory</Link>
                        {/* <span style={selectedDiv == 'A&C' || selectedDiv == 'R&D' ? { opacity: '0' } : { color: "#7cba27" }}> | </span> */}
                        <Link className='service-button twenty-sixteen-fonts one' onClick={() => { setSelectedDiv('R&D') }} style={selectedDiv == 'R&D' ? activeBtnStyle : {}}>Research</Link>
                        {/* <span style={selectedDiv == 'R&D' || selectedDiv == 'T&SD' ? { opacity: '0' } : { color: "#7cba27" }}> | </span> */}
                        <Link className='service-button twenty-sixteen-fonts' onClick={() => { setSelectedDiv('T&SD') }} style={selectedDiv == 'T&SD' ? activeBtnStyle : {}}>Training</Link>
                    </div>
                    {/* <div className="Totalcontainer">
                        <span className='total-text'>Total Services <span style={{ color: "#7cba27" }}> | </span> {pageData.services.advisory.length + pageData.services.research.length + pageData.services.training.length}  </span>
                    </div> */}
                </div>
                <div className='dps-main'>
                    <div className='dps-full'>
                        <div id='A&C' style={selectedDiv == 'A&C' ? { display: 'block' } : { display: 'none' }}>


                            {
                                pageData && pageData["services"].advisory.map((data, index) => {
                                    return Number(index) % 2 == 0 ?

                                        <div div key={data.id} ref={(el) => (PMS.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (PMS.current[index] = el)} className='dps-row' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> :
                                                        <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    // < div className='dps-row' >
                                    //     <div key={data.id} ref={(el) => (PMS.current[index] = el)} className='dps-text-content normal-text '>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>

                                    //     </div>
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //         <div className="d1">
                                    //             <div className="circle-ripple">
                                    //                 <div className='dps-numbering'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div> : 
                                    // < div key={data.id} ref={(el) => (PMS.current[index] = el)} className='dps-row' >
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //     </div>
                                    //     <div className='dps-text-content normal-text  dps-text-content-2'>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                    //         </div>
                                    //         <div className="d1">
                                    //             <div className="circle-ripple-2">
                                    //                 <div className='dps-numbering-2'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                })
                            }



                            {/*                            
                           
                            <div className='dps-row'>
                                <div className='dps-text-content normal-text '>
                                    <div>
                                        <h4>Exploration and Development </h4>
                                        <span>
                                            Embark on a journey from concept to production with our Exploration and Development services. We cover the full spectrum, including concept to prospect maturation, rigorous due diligence, and in-depth G&G data interpretation. Our team ensures comprehensive support through every step, from reservoir characterization and modeling to production forecasting and appraisal. With GERMI, optimize well designs and develop plans that guarantee the highest standards of efficiency and sustainability.
                                        </span>
                                    </div>
                                    <div className='dps-numbering'><span>1</span></div>
                                </div>
                                <div className='dps-img-content'>
                                    <img src={PMImg1} alt="serviceImage" />
                                </div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={PMImg2} alt="pming" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Seismic Data Processing & Interpretation</h4>
                                        <span>
                                            Unlock the secrets beneath the surface with our advanced seismic data processing and interpretation services. We provide you with the clarity and insight needed to make informed decisions on hydrocarbon exploration, utilizing cutting-edge technology for precise subsurface mapping.
                                        </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>2</span></div>

                                </div>

                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content normal-text '>
                                    <div>
                                        <h4>Reservoir Characterization & Inversion Analysis</h4>
                                        <span>
                                            Maximize your reservoir's potential with our comprehensive characterization and inversion analysis. By integrating diverse data sources, we offer a detailed understanding of reservoir properties, enabling optimized extraction strategies and enhanced recovery rates.
                                        </span>

                                    </div>
                                    <div className='dps-numbering'><span>3</span></div>


                                </div>
                                <div className='dps-img-content'>
                                    <img src={PMImg3} alt="serviceImage" />
                                </div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={PMImg4} alt="serviceImage" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Static & Dynamic Reservoir Modeling</h4>
                                        <span>
                                            Transform your reservoir management strategies with our static and dynamic modeling services. Our services offer a detailed visualization of your reservoir, providing critical insights into its behavior over time and ensuring effective production forecasting and management.
                                        </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>4</span></div>
                                </div>

                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content normal-text '>
                                    <div>
                                        <h4>Formation Evaluation & Performance Forecasting</h4>
                                        <span>
                                            Make data-driven decisions with our formation evaluation and performance forecasting services. We provide accurate assessments of your formations' potential and predict performance, enabling strategic planning and optimization of resources.
                                        </span>

                                    </div>
                                    <div className='dps-numbering'><span>5</span></div>
                                </div>
                                <div className='dps-img-content'>
                                    <img src={PMImg5} alt="serviceImage" />
                                </div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={PMImg6} alt="serviceImage" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Field Development & Appraisal</h4>
                                        <span>
                                            Navigate the complexities of field development and appraisal with GERMI's expert guidance. Our strategic planning and techno-economic analysis equip you with the tools to design and implement efficient development plans, ensuring the viability and success of your projects. Navigate the complexities of field development and appraisal with GERMI's expert guidance. Our strategic planning and techno-economic analysis equip you with the tools to design and implement efficient development plans, ensuring the viability and success of your projects.
                                        </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>6</span></div>
                                </div>

                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content normal-text '>
                                    <div>
                                        <h4>Production Enhancement Solutions</h4>
                                        <span>
                                            Elevate your production levels with our tailored enhancement solutions. From advanced recovery techniques to innovative EOR strategies, we help you unlock additional value from your assets, ensuring sustainable and increased production.
                                        </span>

                                    </div>
                                    <div className='dps-numbering'><span>7</span></div>


                                </div>
                                <div className='dps-img-content'>
                                    <img src={PMImg7} alt="serviceImage" />
                                </div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={PMImg8} alt="serviceImage" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Exploration & Development Due Diligence</h4>
                                        <span>
                                            Discover the potential of your assets with our Block Evaluation services. Whether it's uncovering small fields, navigating the complexities of HELP and OALP blocks, or crafting detailed field development plans, we're here to guide you. Our expertise in reservoir characterization, reserve estimation, and compliance ensures your projects are not just viable but also environmentally responsible and aligned with global best practices.
                                        </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>8</span></div>
                                </div>

                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content normal-text '>
                                    <div>
                                        <h4>Reserve Auditing and Certification</h4>
                                        <span>
                                            In the dynamic and complex world of hydrocarbon exploration and development, accurate and reliable reserve estimates are paramount. At GERMI, our Reserve Auditing and Certification services stand as pillars of transparency and trust, ensuring that your hydrocarbon reserves are evaluated with the utmost precision and integrity.
                                        </span>

                                    </div>
                                    <div className='dps-numbering'><span>9</span></div>


                                </div>
                                <div className='dps-img-content'>
                                    <img src={PMImg9} alt="serviceImage" />
                                </div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={PMImg11} alt="serviceImage" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Coal Characterization & CBM Exploration</h4>
                                        <span>
                                            Unlock the energy potential of coal with our seismic-based characterization and CBM exploration services. We offer precise assessments of coal seams for mining and methane extraction, contributing to diversified energy solutions.
                                        </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>10</span></div>


                                </div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content normal-text '>
                                    <div>
                                        <h4>Site Restoration & Environmental Stewardship</h4>
                                        <span>
                                            Commit to environmental excellence with our site restoration services. We calculate restoration costs and develop plans that ensure your sites are returned to their natural state or better, reflecting our dedication to sustainability.
                                        </span>

                                    </div>
                                    <div className='dps-numbering'><span>11</span></div>
                                </div>
                                <div className='dps-img-content'>
                                    <img src={PMImg12} alt="serviceImage" />
                                </div>

                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={PMImg10} alt="serviceImage" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Farm In - Farm Out (FIFO) Advisory</h4>
                                        <span>
                                            Navigate the complexities of E&P partnerships with our FIFO advisory services. We facilitate negotiations and manage agreements, sharing risks and resources efficiently to drive collective success.
                                        </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>12</span></div>


                                </div>
                            </div> */}
                        </div>
                        <div id='R&D' style={selectedDiv == 'R&D' ? { display: 'block' } : { display: 'none' }}>



                            {
                                pageData && pageData["services"].research.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?


                                        <div div key={data.id} ref={(el) => (PMS1.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (PMS1.current[index] = el)} className='dps-row' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> :
                                                        <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    // < div className='dps-row' >
                                    //     <div key={data.id} ref={(el) => (PMS1.current[index] = el)} className='dps-text-content normal-text '>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>

                                    //     </div>
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="hello" />
                                    //         <div className="d1">
                                    //             <div className="circle-ripple">
                                    //                 <div className='dps-numbering'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div> : 
                                    // < div key={data.id} ref={(el) => (PMS1.current[index] = el)} className='dps-row' >
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="hello" />
                                    //     </div>
                                    //     <div className='dps-text-content normal-text  dps-text-content-2'>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>
                                    //         <div className="d1">
                                    //             <div className="circle-ripple-2">
                                    //                 <div className='dps-numbering-2'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                })
                            }



                            {/* <div className='dps-row'>
                                <div className='dps-text-content normal-text '>
                                    <div>
                                        <h4>Geological and Geophysical Analysis</h4>
                                        <span>
                                            Our dedicated Research Services in Geological and Geophysical Analysis are at the forefront of unraveling the complexities of the subsurface environment. Our commitment to enhancing seismic interpretation through advanced methodologies ensures that we turn challenges into opportunities for innovation and efficiency.
                                        </span>
                                    </div>
                                    <div className='dps-numbering'><span>1</span></div>
                                </div>
                                <div className='dps-img-content'>
                                    <img src={PMImg2_1} alt="serviceImage" />
                                </div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={PMImg2_2} alt="pming" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Seismic Imaging</h4>
                                        <span>
                                            Our advanced Reverse Time Migration (RTM) and Full Waveform Inversion (FWI) services provide high-resolution imaging capabilities critical for complex geological settings. Using state-of-the-art algorithms, these techniques offer deeper insights into subsurface structures, enabling more accurate hydrocarbon exploration and extraction.
                                        </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>2</span></div>

                                </div>

                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content normal-text '>
                                    <div>
                                        <h4>Petrophysical Analysis</h4>
                                        <span>
                                            Our in-house developed software for well log analysis with a Python interface utilizes machine learning to enhance petrophysical analysis. This tool allows for seamless integration of complex algorithms in machine learning to improve accuracy in log interpretation and reservoir characterization.
                                        </span>

                                    </div>
                                    <div className='dps-numbering'><span>3</span></div>


                                </div>
                                <div className='dps-img-content'>
                                    <img src={PMImg2_3} alt="serviceImage" />
                                </div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={PMImg41} alt="serviceImage" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>AI/ML based Solutions for E&P Sector</h4>
                                        <span>
                                            Leverage our machine learning expertise to drive efficiency in Exploration & Production (E&P) and the coal sector. Our models are trained on vast datasets to predict geological features, optimize drilling operations, and enhance safety measures, leading to reduced costs and improved operational efficiencies.
                                        </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>4</span></div>
                                </div>

                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content normal-text '>
                                    <div>
                                        <h4>Software Development for Seismic Analysis</h4>
                                        <span>
                                            Revolutionize your operations with our cutting-edge Software Development solutions. From coal resource estimations to the application of AI and machine learning in oil and gas, our bespoke software solutions and plugins are designed to enhance seismic data processing and interpretation. Embrace the future with IoT-based analytics, seismic imaging, and more, driving efficiency and insight across your projects.
                                        </span>

                                    </div>
                                    <div className='dps-numbering'><span>5</span></div>
                                </div>
                                <div className='dps-img-content'>
                                    <img src={PMImg2_5} alt="serviceImage" />
                                </div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={PMImg2_6} alt="serviceImage" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Geothermal Energy Exploration</h4>
                                        <span>
                                            Explore the power of the Earth with our geothermal exploration services. By identifying and evaluating underground heat sources, we help you harness clean and renewable energy, contributing to the global energy transition.
                                        </span>

                                    </div>
                                    <div className='dps-numbering-2'><span>6</span></div>
                                </div>

                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content normal-text '>
                                    <div>
                                        <h4>Geological CO2 Sequestration</h4>
                                        <span>
                                            Embrace sustainability with our CO2 sequestration solutions. By capturing and storing carbon dioxide underground, we offer a path to reducing greenhouse gas emissions and combating climate change, ensuring a greener future for all.
                                        </span>
                                    </div>
                                    <div className='dps-numbering'><span>7</span></div>


                                </div>
                                <div className='dps-img-content'>
                                    <img src={PMImg2_7} alt="serviceImage" />
                                </div>
                            </div> */}
                        </div>
                        <div id='T&SD' style={selectedDiv == 'T&SD' ? { display: 'block' } : { display: 'none' }}>

                            {
                                pageData && pageData["services"].training.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?

                                        <div div key={data.id} ref={(el) => (PMS2.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (PMS2.current[index] = el)} className='dps-row' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> :
                                                        <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    // < div className='dps-row' >
                                    //     <div key={data.id} ref={(el) => (PMS2.current[index] = el)} className='dps-text-content normal-text '>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>

                                    //     </div>
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="hello" />
                                    //         <div className="d1">
                                    //             <div className="circle-ripple">
                                    //                 <div className='dps-numbering'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div> : 
                                    // < div key={data.id} ref={(el) => (PMS2.current[index] = el)} className='dps-row' >
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="hello" />
                                    //     </div>
                                    //     <div className='dps-text-content normal-text  dps-text-content-2'>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>
                                    //         <div className="d1">
                                    //             <div className="circle-ripple-2">
                                    //                 <div className='dps-numbering-2'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                })
                            }

                            {/* <div className='dps-row'>
                                <div className='dps-text-content normal-text '>
                                    <div>
                                        <h4>Training and Skill Development</h4>
                                        <span>
                                            Build the future of energy with our Training and Skill Development programs. GERMI is dedicated to nurturing talent through comprehensive workshops, internships, and specialized training in reservoir simulation and more. Our portal for petroleum sector skills development is your gateway to becoming a leader in the energy domain, equipped with knowledge and expertise that makes a difference.
                                        </span>
                                    </div>
                                    <div className='dps-numbering'><span>1</span></div>


                                </div>
                                <div className='dps-img-content'>
                                    <img src={PMImg3_1} alt="serviceImage" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            {/* Success Stories Start */}
            {/* Success Stories Start */}
            <section className='dep-sec-bg'>
                <NewSuccessStories departmentId={3} />
            </section>
            {/* Success Stories End */}

            {/* <section>
                <HomeCounter />
            </section> */}

            <section className='dep-sec-bg'>
                {/* <NewDepartmentTeam /> */}
                <OurDepartmentTeam teamData={pageData.team} department={3} />
                {/* <ComingSoonTeam /> */}
            </section>
            {/* <section style={{ backgroundColor: "#F8F8F8" }}>
                <NewDepartmentTeam />
            </section> */}
            <section >
                <HomeNoticesDepartment department={3} />
            </section>

            {/* <section>
                <OurDepartmentTeam />
            </section> */}


            <section>
                <ContactUsDepartment department={3} />
            </section>

            {/* <section className="section-lgb other-dep-check-out-div">
                <div className="container pbmit-col-stretched-yes pbmit-col-right">

                    <div className="row main-div">
                        <h2 className='pbmit-service-title data-direction dd-mb'>Other Depatments To Checkout</h2>
                        <div className="col-12">

                            <div className="pbmit-col-stretched-right">

                                <div className="swiper-slider" data-autoplay="true" data-loop="true" data-dots="false" data-arrows="false" data-columns="4" data-margin="30" data-effect="slide">
                                    <div className="swiper-wrapper">
                                        <Swiper
                                            modules={[Navigation, Scrollbar, A11y, Autoplay]}
                                            spaceBetween={10}
                                            slidesPerView={4}
                                            

                                            loop={true}>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    

                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage1} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Renewable Management</Link>
                                                                        </h3>
                                                                        <div className="pbmit-service-category">
                                                                           
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>

                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage2} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Petroleum Management</Link>
                                                                        </h3>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage3} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Environment Management</Link>
                                                                        </h3>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage4} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Petroleum Research Management</Link>
                                                                        </h3>
                                                                        

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* </div> */}
            <section className="rs-service-grid-3 pt-70 pb-120">
                {/* <div className="heading-container">
                    <h4 id="title1" className="title-animation-about-us">Other Departments to Checkout</h4>
                    <div className=" div-for-hr-animation"><hr id="hr1" className="animate-hr-tag-about-us" /><hr id="hr2" className="animate-hr-tag-about-us" />
                    </div>
                </div> */}
                <OnScrollAnimation title1="odcpm1" title2="odcpm2" hr1="odcpm3" hr2="odcpm4" heading="Other Departments to Checkout" subheading="" />
                <div className='nod-container'>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage1} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={ntm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/new-technology-management/5/0">New Technology<br />Management</Link></h4>
                                    {/* <p>Petroleum Management </p> */}
                                    <Link to="/departments/new-technology-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage2} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={rm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/renewable-management/5/0">Renewable<br /> Management</Link></h4>
                                    {/* <p> Renewable Management </p> */}
                                    <Link to="/departments/renewable-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>

                     
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage5} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={prm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/petroleum-research-management/5/0">Petroleum Research<br />Management</Link></h4>
                                    {/* <p>Petroleum  Management </p> */}
                                    <Link to="/departments/petroleum-research-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage4} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={em} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/environment-management/5/0">Environment<br /> Management</Link></h4>
                                    {/* <p> Environment Management  </p> */}
                                    <Link to="/departments/environment-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default RenewableManagement;
