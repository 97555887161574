import React, { useEffect, useRef, useState } from 'react'
import "./NewTechnologyManagement.css"
import './NewOtherDept.css'
import he from 'he';
import { Link, useLocation, useParams } from 'react-router-dom';
import ContactUsDepartment from '../ContactUsDepartment';
// import HomeNotices from '../../Home/HomeNotices/HomeNotices';
import HomeNotices from '../../Home2/HomeNotices/HomeNotices'
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import breadcrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/EM.jpg'
import ScrollToTop from '../../ScrollToTop';
import layer from '../../../Images_1/Departments/Images/emdmain.png'
import NewDepartmentTeam from '../NewDepartmentTeam/NewDepartmentTeam'
import NewSuccessStories from './NewSuccessStories/NewSuccessStories';
import ServiceImage1 from '../../../Images_1/Departments/DepartmentHomeImages/NewTechManagement.jpg'
import ServiceImage2 from '../../../Images_1/Departments/DepartmentHomeImages/RenewableManagement.jpg'
import ServiceImage3 from '../../../Images_1/Departments/DepartmentHomeImages/PetroluemManagement.jpg'
import ServiceImage4 from '../../../Images_1/Departments/DepartmentHomeImages/EnvironmentManagement.jpg'
import ServiceImage5 from '../../../Images_1/Departments/DepartmentHomeImages/PetroleumResearchManagement.jpg'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import OurDepartmentTeam from '../OurDepartmentTeam';
import pageData from "./json/EnvironmentDepartment.json"
import TheCounter from "../TheCounter"
import ScrollTrigger from 'react-scroll-trigger'
import OnScrollAnimation from '../../OnScrollAnimation';
import HomeNoticesDepartment from '../../Home2/HomeNotices/HomeNoticesDepartment';
import ntm from '../../../Images_1/Departments/DepartmentHomeImages/NTM.png'
import rm from '../../../Images_1/Departments/DepartmentHomeImages/RM.png'
import pm from '../../../Images_1/Departments/DepartmentHomeImages/PM.png'
import prm from '../../../Images_1/Departments/DepartmentHomeImages/PRM.png'
import { useDispatch } from 'react-redux';
import { aboutdetail } from './store/aboutdetailSlice';
import { Helmet } from 'react-helmet';

const RenewableManagement = () => {
    const { id } = useParams();
    const { id2 } = useParams();
    const containerWrapperRef = useRef(null);
    const topWrapperRef = useRef(null);
    const EMS = useRef([]);
    const EMS1 = useRef([]);
    const EMS2 = useRef([]);
    const [aboutdata, setAboutdata] = useState("");
    const [abouttitle, setAbouttitle] = useState("");
    const dispatch=useDispatch();
    const [SelectedService, setSelectedService] = useState(['', '', '']);
    const [DrawerDiv, setDrawerDiv] = useState('draw1');
    const [selectedDiv, setSelectedDiv] = useState(id == 1 ? "R&D" : id == 2 ? "T&SD" : 'A&C');
    const activeBtnStyle = {
        // backgroundColor: '#d2611c',
        backgroundColor: '#7cba27',
        color: '#ffffff',
        transition: '0.4s ease-in-out',
    }
    const [counterState, setCounterState] = useState(false)
    useEffect(() => {

        if (id == 5) {
            // if (topWrapperRef.current) {
            //     // Scroll the div into view
            //     topWrapperRef.current.scrollIntoView({ behavior: 'smooth' });

            //     // Set focus on the div
            //     topWrapperRef.current.focus();
            //   }
        } else {

            // console.log("selectedDivid" + selectedDiv)
            // if(id==0){

            // }
            // else 
            if (id == 1) {


                var index3 = pageData["services"].research.findIndex(
                    (x) => x.id === id2
                );
                // console.log(index3 + "index")
                if (index3 !== null && EMS1.current[index3]) {



                    EMS1.current[index3].scrollIntoView({ behavior: 'smooth' });
                    EMS1.current[index3].focus();
                }

            } else if (id == 2) {


                var index3 = pageData["services"].training.findIndex(
                    (x) => x.id === id2
                );
                if (index3 !== null && EMS2.current[index3]) {
                    EMS2.current[index3].scrollIntoView({ behavior: 'smooth' });
                    EMS2.current[index3].focus();
                }

            } else {
                // console.log(pageData["services"].advisory)
                var index3 = pageData["services"].advisory.findIndex(
                    (x) => x.id == id2
                );
                // console.log(index3 + "index" + id2)
                if (index3 !== null && EMS.current[index3]) {
                    EMS.current[index3].scrollIntoView({ behavior: 'smooth' });
                    EMS.current[index3].focus();
                }
            }
            // if (id2=="EMS") {
            //     // Scroll the div into view
            //     EMS.current.scrollIntoView({ behavior: 'smooth' });

            //     // Set focus on the div
            //     EMS.current.focus();


            //   }

            // if (containerWrapperRef.current) {
            //   // Scroll the div into view
            //   containerWrapperRef.current.scrollIntoView({ behavior: 'smooth' });

            //   // Set focus on the div
            //   containerWrapperRef.current.focus();
            // }
        }

         var jsonparams = {
                            "modulename": "EM",
                        }
                        dispatch(aboutdetail(jsonparams)).then((data) => {
                            console.log(data.payload);
                            if(data.payload.status=="1"||data.payload.status==1){
                            setAbouttitle(data.payload.title);
                            setAboutdata(data.payload.description)
                            }      
                        });
    }, [selectedDiv]);
    return (
        <div>

<Helmet>
      <title>
      Environment management &amp; research institute in Gujarat | BMP, AMPTS
      </title>
      <meta name="keywords" content="Environment management &amp; research institute in Gujarat, Biomethane Potential Test
(BMP), Feedstock assessment for Biogas potential, Biogas compositional analysis, NABL
Accredited Lab for Environment Testing, Automated Methane Test Potential System (AMPTS)"/>
<meta name="description" content="As a leading environment management institute firm in Gujarat, we specialize in
Biomethane Potential Test (BMP), Feedstock assessment for Biogas potential, Biogas
compositional analysis, NABL Accredited Lab for Environment Testing, Automated Methane
Test Potential System (AMPTS)."/>
    </Helmet>
            <ScrollToTop />
            {/* <DepartmentHeader styles="header-transparent header-style-2" searchDisable buttonStyle="bg-color-white" /> */}
            <div ref={topWrapperRef} className='breadcrumb-margin'>
                <BreadCrumbs className="breadcrumb-text-class" parent="Departments" child="Environment Management" parentPath="/departments" imgPath={breadcrumbImage} />
            </div>
            <div className='new-tech-whole-section dep-sec-bg'>
                <section className='new-technology-department-about-sectionenv'>

                    <img className="new-department-inner-image new-tech-image-translateCustomSlider3" src={layer} />
                    <div className='new-tech-title-spacing'>
                        <h1 className='new-tech-custom-slider-changing-text  new-tech-image-translateCustomSlider heading-fonts'>{abouttitle==""?"Environment Management":abouttitle}</h1>

                        <h6 className='new-tech-custom-slider-changing-paragraph normal-text new-tech-image-translateCustomSlider1' style={{ textAlign: "justify" }} >{aboutdata==""?"The Environment Management Department conducts research on national and global environmental issues, emphasizing pollution management, energy efficiency, renewable energy, and waste-to-energy optimization. It assists industries in implementing innovative technologies and holistic interventions. Research addresses emerging environmental challenges, particularly in Gujarat and nationwide, aligned with India's commitments to multilateral environmental agreements, prioritizing India's environmental and energy efficiency needs.":aboutdata}</h6>
                    </div>
                </section>
            </div>

            {/* <section> <HomeCounterNew /> </section> */}

            <section className='dep-sec-bg'>
                <div>
                    <section className='home-counterNew'>
                        <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
                            <div className='top-padding-zero home-counterNew-container'>
                                {/* <h2 className='home-counterNew-headingNew'>Success in Numbers</h2> */}
                                <div className='counter-div-containerNew'>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={200} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text normal-text sub-heading'>Institutional Biogas Plants Inspection Completed across the Gujarat State</span>
                                        {/* <span className='counter-textNew normal-text normal-text'>Inspection Completed across the Gujarat State</span> */}
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={60} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text normal-text sub-heading'>Industries were audited under Environmental Audit.</span>
                                        {/* <span className='counter-textNew normal-text normal-text'>Trainde</span> */}
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={50} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text normal-text sub-heading'>Industries were guided for Environmental Adequacy Services </span>
                                        {/* <span className='counter-textNew normal-text normal-text'>Certified</span> */}
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={150} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text normal-text sub-heading'>Professionals Trained for Environmental Audit</span>
                                        {/* <span className='counter-textNew normal-text normal-text'>and Training of Master Trainers</span> */}
                                    </div>
                                    <div className='counter-divNew'>
                                        <h3 className='counter-numbersNew counter-fonts'>
                                            {counterState && <TheCounter maxValue={100} duration="300" />}
                                            <span className='counter-symbolNew'>{"+"}</span>
                                        </h3>
                                        <span className='counter-textNew normal-text normal-text sub-heading'>Municipal solid waste samples were analyzed for Waste to Energy services</span>
                                        {/* <span className='counter-textNew normal-text normal-text'>(G&G)</span> */}
                                    </div>
                                </div>
                            </div>
                        </ScrollTrigger>
                    </section>

                </div>
                {/* <HomeCounterNew /> */}
            </section>


            <section className='dep-sec-bg'>
                {/* <div className="heading-container">
                    <h4>Our Services</h4>
                    <h2 id="title1" className="title-animation-about-us">Services We Provide</h2>
                    <div className=" div-for-hr-animation"><hr id="hr1" className="animate-hr-tag-about-us" /><hr id="hr2" className="animate-hr-tag-about-us" />
                    </div>
                </div> */}
                <OnScrollAnimation title1="em1" title2="em2" hr1="em3" hr2="em4" heading="Services We Provide" subheading="" />
                <div ref={containerWrapperRef} className='top-container'>
                    <div className='service-tab-container'>
                        <Link className='service-button twenty-sixteen-fonts one' onClick={() => { setSelectedDiv('A&C') }} style={selectedDiv == 'A&C' ? activeBtnStyle : {}}>Advisory</Link>
                        {/* <span style={selectedDiv == 'A&C' || selectedDiv == 'R&D' ? { opacity: '0' } : { color: "#7cba27" }}> | </span> */}
                        <Link className='service-button twenty-sixteen-fonts one' onClick={() => { setSelectedDiv('R&D') }} style={selectedDiv == 'R&D' ? activeBtnStyle : {}}>Research</Link>
                        {/* <span style={selectedDiv == 'R&D' || selectedDiv == 'T&SD' ? { opacity: '0' } : { color: "#7cba27" }}> | </span> */}
                        <Link className='service-button twenty-sixteen-fonts one' onClick={() => { setSelectedDiv('T&SD') }} style={selectedDiv == 'T&SD' ? activeBtnStyle : {}}>Training</Link>
                        <Link className='service-button twenty-sixteen-fonts' onClick={() => { setSelectedDiv('A&SD') }} style={selectedDiv == 'A&SD' ? activeBtnStyle : {}}>Analytical</Link>
                    </div>
                    {/* <div className="Totalcontainer">
                        <span className='total-text'>Total Services <span style={{ color: "#7cba27" }}> | </span> {pageData.services.advisory.length + pageData.services.research.length + pageData.services.analytical.length + pageData.services.training.length} </span>
                    </div> */}
                </div>
                <div className='dps-main'>
                    <div className='dps-full'>
                        <div id='A&C' style={selectedDiv == 'A&C' ? { display: 'block' } : { display: 'none' }}>
                            {
                                pageData && pageData["services"].advisory.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?

                                        <div key={data.id} ref={(el) => (EMS.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>

                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        :


                                        <div key={data.id} ref={(el) => (EMS.current[index] = el)} className='dps-row ' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> :
                                                        <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    // < div className='dps-row' >
                                    //     <div key={data.id} ref={(el) => (EMS.current[index] = el)} className='dps-text-content normal-text '>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>

                                    //     </div>
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="hello" />
                                    //         <div className="d1">
                                    //             <div className="circle-ripple">
                                    //                 <div className='dps-numbering'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div> : 
                                    // < div key={data.id} ref={(el) => (EMS.current[index] = el)} className='dps-row' >
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="hello" />
                                    //     </div>
                                    //     <div className='dps-text-content normal-text  dps-text-content-2'>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                    //         </div>
                                    //         <div className="d1">
                                    //             <div className="circle-ripple-2">
                                    //                 <div className='dps-numbering-2'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                })
                            }
                            {/* <div className='dps-row' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}}>
                                <div className={`dps-text-content normal-text ${SelectedService[0] === 'srv1' ? "dp-bg-design" : ""}`} style={SelectedService[0] == 'srv1' ? { width: '100%', padding: '100px 40px 70px 40px' } : {}}>
                                    <div>
                                        <h4>Environmental Audit Services</h4>
                                        <span><b>Site visits:</b> With respect to seasonal changes, Industrial Visits carried out to collect samples of air, water & wastewater, hazardous waste, noise monitoring, etc.
                                        </span>
                                        <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                            <span>
                                                <span><b>Sampling  &  Analysis of Environmental Audit Parameters:</b> Sampling of Ambient Air, Stack Emission, Water, Wastewater and Hazardous Waste as per standard procedures.</span>
                                                <span>• Reviewing manufacturing process, handling of raw materials, health & safety practices, electrical and water consumption, ETP process, efficiency of air pollution control devices and hazardous waste handling practices.</span>
                                                <span>• Analysis of samples in the laboratory (NABL accredited) as per standard procedures and methods.</span>
                                                <b>Report Preparation</b>
                                            </span>
                                        </div>

                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                        </div>
                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='dps-img-content' style={SelectedService[0] == 'srv1' ? { display: 'none' } : { display: 'block' }}>
                                    <img src={dpservice1} alt="hello" />
                                </div>
                                <div className='dps-numbering' style={SelectedService[0] == 'srv1' ? { display: 'none' } : { display: 'block' }}><span>1</span></div>
                            </div> */}
                            {/* <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={dpservice2} alt="hello" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Monthly Monitoring for Industries (Effluent, Ambient Air, Stack Air & Noise)</h4>
                                        <span>
                                            Sampling of Ambient Air, Stack Emission, Water, Wastewater and Hazardous Waste & Analysis of samples in the laboratory (NABL accredited) as per standard procedures and methods.
                                        </span>
                                    </div>
                                </div>
                                <div className='dps-numbering-2'><span>2</span></div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Adequacy Certificate for Industrial Units and ETP's</h4>
                                        <div>
                                            <span><b>Site visits:</b> Site visits carried out to physically verify the proposed or installed ETP units, their dimensions and its capacity.</span>
                                            <span>• Calculation and Verification of design with standard data and comparison.</span>
                                            <span><b>Interpretation:</b> Preparation of adequacy certificate that states that proposed ETP Units are adequate for treatment of wastewater.</span>
                                        </div>
                                    </div>

                                </div>
                                <div className='dps-img-content'>
                                    <img src={dpservice3} alt="hello" />
                                </div>
                                <div className='dps-numbering'><span>3</span></div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={dpservice4} alt="hello" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Design Verification and Adequacy of Environmental Management System (EMS)</h4>
                                        <span>
                                            Verification of Gasifier,  Estimation and Calculation of pollution load of any particular industry etc., Adequacy Certificate Provider as Schedule-1 Environment Auditor.
                                        </span>
                                    </div>

                                </div>
                                <div className='dps-numbering-2'><span>4</span></div>
                            </div> */}
                        </div>
                        <div id='R&D' style={selectedDiv == 'R&D' ? { display: 'block' } : { display: 'none' }}>
                            {
                                pageData && pageData["services"].research.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?

                                        <div div key={data.id} ref={(el) => (EMS1.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (EMS1.current[index] = el)} className='dps-row' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> :
                                                        <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    // < div className='dps-row' >
                                    //     <div key={data.id} ref={(el) => (EMS1.current[index] = el)} className='dps-text-content normal-text '>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>

                                    //     </div>
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //         <div className="d1">
                                    //             <div className="circle-ripple">
                                    //                 <div className='dps-numbering'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div> : 
                                    // < div key={data.id} ref={(el) => (EMS1.current[index] = el)} className='dps-row' >
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //     </div>
                                    //     <div className='dps-text-content normal-text  dps-text-content-2'>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>
                                    //         <div className="d1">
                                    //             <div className="circle-ripple-2">
                                    //                 <div className='dps-numbering-2'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                })
                            }
                            {/* <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Third Party Inspections - Biogas Plant </h4>
                                        <span>
                                            Environment wing of GERMI evaluates the biogas plant under commissioning at different stages as per design approved by GEDA. Objectives of the project is to ensure that sustainable biogas plants are established and the government subsidy is utilized
                                            meaningfully.
                                        </span>
                                    </div>

                                </div>
                                <div className='dps-img-content'>
                                    <img src={dpservice5} alt="hello" />
                                </div>
                                <div className='dps-numbering'><span>1</span></div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={dpservice6} alt="hello" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Industrial Effluent Analysis</h4>
                                        <div>
                                            <span><b>Site visits:</b> Site visits carried out to physically collect the Effluent samples.</span>
                                            <span>• Analysis of different parameters of the Effluent samples</span>
                                            <span><b>Interpretation:</b>Preparation of Analysis report and state the feasibility of the Effluent.</span>
                                        </div>
                                    </div>

                                </div>
                                <div className='dps-numbering-2'><span>2</span></div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Waste to Energy services  </h4>
                                        <span>
                                            Sampling of  solid waste and analyzed for physical & compositional analysis for waste to energy conversion.
                                        </span>
                                    </div>

                                </div>
                                <div className='dps-img-content'>
                                    <img src={dpservice7} alt="hello" />
                                </div>
                                <div className='dps-numbering'><span>3</span></div>
                            </div> */}
                        </div>
                        <div id='T&SD' style={selectedDiv == 'T&SD' ? { display: 'block' } : { display: 'none' }}>
                            {
                                pageData && pageData["services"].training.map((data, index) => {
                                    return Number(data.id) % 2 == 0 ?

                                        <div div key={data.id} ref={(el) => (EMS2.current[index] = el)} className='dps-row dps-row-2' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >

                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div>
                                                        : <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>

                                            </div>
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                                <div className="d1">
                                                    <div className="circle-ripple">
                                                        <div className='dps-numbering'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        :


                                        <div div key={data.id} ref={(el) => (EMS2.current[index] = el)} className='dps-row' id='srv1' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}} >
                                            <div className='dps-img-content'>
                                                <img src={data.imageSrc} alt="image" />
                                            </div>
                                            <div className='dps-text-content normal-text   dps-text-content-2'>
                                                <div>
                                                    <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                    <span>
                                                        {data.description}
                                                    </span>


                                                    <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                                        <span>
                                                            {data.extraDescription}
                                                        </span>
                                                        <ul className='serv-li'>
                                                            {
                                                                data.list && data.list.map(
                                                                    (aList) => (
                                                                        <li>
                                                                            {aList}
                                                                        </li>
                                                                    )
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                    {data.list.length > 0 || data.extraDescription != "" ?
                                                        <div className='see-more-text-card'>
                                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                                        </div> :
                                                        <></>}
                                                    <div className='see-more-text-card'>
                                                        <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                                    </div>





                                                </div>
                                                <div className="d1">
                                                    <div className="circle-ripple-2">
                                                        <div className='dps-numbering-2'><span>{data.id}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    // < div className='dps-row' >
                                    //     <div key={data.id} ref={(el) => (EMS2.current[index] = el)} className='dps-text-content normal-text '>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>

                                    //     </div>
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //         <div className="d1">
                                    //             <div className="circle-ripple">
                                    //                 <div className='dps-numbering'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div> : 
                                    // < div key={data.id} ref={(el) => (EMS2.current[index] = el)} className='dps-row' >
                                    //     <div className='dps-img-content'>
                                    //         <img src={data.imageSrc} alt="image" />
                                    //     </div>
                                    //     <div className='dps-text-content normal-text  dps-text-content-2'>
                                    //         <div>
                                    //             <h4>{data.title}</h4>
                                    //             <span>
                                    //                 {data.description}
                                    //             </span>
                                    //         </div>
                                    //         <div className="d1">
                                    //             <div className="circle-ripple-2">
                                    //                 <div className='dps-numbering-2'><span>{data.id}</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                })
                            }
                            {/* <div className='dps-row' id='srv8' style={SelectedService[1] == 'srv8' ? { height: '100%' } : {}}>
                                <div className='dps-img-content' style={SelectedService[1] == 'srv8' ? { display: 'none' } : { display: 'block' }}>
                                    <img src={dpservice8} alt="hello" />
                                </div>
                                <div className={`dps-text-content-2 dps-text-content normal-text ${SelectedService[1] === 'srv8' ? "dp-bg-design" : ""}`} style={SelectedService[1] == 'srv8' ? { width: '100%', padding: '100px 40px 70px 40px' } : {}}>
                                    <div>
                                        <h4>Technical Appraisal for the Bio-CNG Projects</h4>
                                        <span>
                                            <span><b>Site visits:</b> Site visits carried out to physically verify the proposed or installed Bio-CNG units, their dimensions and its capacity.</span>
                                            <span>• Calculation and Technical Verification of design with standard data check the feasibility of proposed plant.</span>
                                        </span>
                                        <div style={SelectedService[1] == 'srv8' ? { display: 'block' } : { display: 'none' }}>
                                            <span><b>Interpretation:</b> Preparation of Technical Appraisal report that technically support the proposed Bio-CNG plant. It also contains observation and suggestion to improve the efficiency of proposed plant.</span>
                                        </div>

                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService([SelectedService[0], 'srv8', SelectedService[2]]) }} style={SelectedService[1] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                        </div>
                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService([SelectedService[0], '', SelectedService[2]]) }} style={SelectedService[1] == 'srv8' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                        </div>
                                    </div>

                                </div>
                                <div className='dps-numbering-2' style={SelectedService[1] == 'srv8' ? { display: 'none' } : { display: 'block' }}><span>1</span></div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Water Analysis (Drinking water, Ground Water, Surface water, Purifier water)</h4>
                                        <div>
                                            <span><b>Site visits:</b> Site visits carried out to physically collect the Water samples.</span>
                                            <span>• Analysis of different parameters of the Water samples.</span>
                                            <span><b>Interpretation:</b> Preparation of Analysis report and state the feasibility of the Water.</span>
                                        </div>
                                    </div>

                                </div>
                                <div className='dps-img-content'>
                                    <img src={dpservice9} alt="hello" />
                                </div>
                                <div className='dps-numbering'><span>2</span></div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={dpservice10} alt="hello" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Extensive Soil Analysis</h4>
                                        <div>
                                            <span><b>Site visits:</b> Site visits carried out to physically collect the soil samples.</span>
                                            <span>• Analysis of different parameters of the soil samples.</span>
                                            <span><b>Interpretation:</b> Preparation of Analysis report and state the feasibility of the soil.</span>

                                        </div>
                                    </div>

                                </div>
                                <div className='dps-numbering-2'><span>3</span></div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Environmental Training & Awareness </h4>
                                        <span>
                                            GERMI has work as a training institute in the field of environment management in the State through organizing training programme, workshops, seminars on various topics of environment for Government organization, Industries and academia etc.
                                        </span>
                                    </div>

                                </div>
                                <div className='dps-img-content'>
                                    <img src={dpservice11} alt="hello" />
                                </div>
                                <div className='dps-numbering'><span>4</span></div>
                            </div> */}
                        </div>
                        <div id='A&SD' style={selectedDiv == 'A&SD' ? { display: 'block' } : { display: 'none' }}>
                            {
                                pageData && pageData["services"].analytical.map((data) => {
                                    return Number(data.id) % 2 == 0 ? < div className='dps-row dps-row-2' >
                                        <div className='dps-text-content normal-text '>
                                            <div>
                                                <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                <span>
                                                    {data.description}
                                                </span>
                                            </div>
                                            <div className='see-more-text-card'>
                                                <Link to="/sampleanalysisform" className='see-more-button-card' target='_blank'><h6 className=' text-color-card static-small-text'>Inquire Now</h6></Link>
                                            </div>

                                        </div>
                                        <div className='dps-img-content'>
                                            <img src={data.imageSrc} alt="image" />
                                            <div className="d1">
                                                <div className="circle-ripple">
                                                    <div className='dps-numbering'><span>{data.id}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : < div className='dps-row' >
                                        <div className='dps-img-content'>
                                            <img src={data.imageSrc} alt="image" />
                                        </div>
                                        <div className='dps-text-content normal-text  dps-text-content-2'>
                                            <div>
                                                <h4 className='thirty-eighteen-fonts'>{data.title}</h4>
                                                <span>
                                                    {data.description}
                                                </span>
                                            </div>
                                            <div className='see-more-text-card'>
                                                <Link to="/sampleanalysisform" className='see-more-button-card' target='_blank'><h6 className=' text-color-card static-small-text'>Inquire Now</h6></Link>
                                            </div>
                                            <div className="d1">
                                                <div className="circle-ripple-2">
                                                    <div className='dps-numbering'><span>{data.id}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>;
                                })
                            }
                            {/* <div className='dps-row' style={SelectedService[0] == 'srv1' ? { height: '100%' } : {}}>
                                <div className={`dps-text-content normal-text ${SelectedService[0] === 'srv1' ? "dp-bg-design" : ""}`} style={SelectedService[0] == 'srv1' ? { width: '100%', padding: '100px 40px 70px 40px' } : {}}>
                                    <div>
                                        <h4>Environmental Audit Services</h4>
                                        <span><b>Site visits:</b> With respect to seasonal changes, Industrial Visits carried out to collect samples of air, water & wastewater, hazardous waste, noise monitoring, etc.
                                        </span>
                                        <div style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }}>
                                            <span>
                                                <span><b>Sampling  &  Analysis of Environmental Audit Parameters:</b> Sampling of Ambient Air, Stack Emission, Water, Wastewater and Hazardous Waste as per standard procedures.</span>
                                                <span>• Reviewing manufacturing process, handling of raw materials, health & safety practices, electrical and water consumption, ETP process, efficiency of air pollution control devices and hazardous waste handling practices.</span>
                                                <span>• Analysis of samples in the laboratory (NABL accredited) as per standard procedures and methods.</span>
                                                <b>Report Preparation</b>
                                            </span>
                                        </div>

                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService(['srv1', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == '' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>Read More</h6></button>
                                        </div>
                                        <div className='see-more-text-card'>
                                            <button onClick={() => { setSelectedService(['', SelectedService[1], SelectedService[2]]) }} style={SelectedService[0] == 'srv1' ? { display: 'block' } : { display: 'none' }} className='see-more-button-card'><h6 className=' text-color-card static-small-text'>View Less</h6></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='dps-img-content' style={SelectedService[0] == 'srv1' ? { display: 'none' } : { display: 'block' }}>
                                    <img src={dpservice1} alt="hello" />
                                </div>
                                <div className='dps-numbering' style={SelectedService[0] == 'srv1' ? { display: 'none' } : { display: 'block' }}><span>1</span></div>
                            </div> */}
                            {/* <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={dpservice2} alt="hello" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Monthly Monitoring for Industries (Effluent, Ambient Air, Stack Air & Noise)</h4>
                                        <span>
                                            Sampling of Ambient Air, Stack Emission, Water, Wastewater and Hazardous Waste & Analysis of samples in the laboratory (NABL accredited) as per standard procedures and methods.
                                        </span>
                                    </div>
                                </div>
                                <div className='dps-numbering-2'><span>2</span></div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-text-content'>
                                    <div>
                                        <h4>Adequacy Certificate for Industrial Units and ETP's</h4>
                                        <div>
                                            <span><b>Site visits:</b> Site visits carried out to physically verify the proposed or installed ETP units, their dimensions and its capacity.</span>
                                            <span>• Calculation and Verification of design with standard data and comparison.</span>
                                            <span><b>Interpretation:</b> Preparation of adequacy certificate that states that proposed ETP Units are adequate for treatment of wastewater.</span>
                                        </div>
                                    </div>

                                </div>
                                <div className='dps-img-content'>
                                    <img src={dpservice3} alt="hello" />
                                </div>
                                <div className='dps-numbering'><span>3</span></div>
                            </div>
                            <div className='dps-row'>
                                <div className='dps-img-content'>
                                    <img src={dpservice4} alt="hello" />
                                </div>
                                <div className='dps-text-content normal-text dps-text-content-2'>
                                    <div>
                                        <h4>Design Verification and Adequacy of Environmental Management System (EMS)</h4>
                                        <span>
                                            Verification of Gasifier,  Estimation and Calculation of pollution load of any particular industry etc., Adequacy Certificate Provider as Schedule-1 Environment Auditor.
                                        </span>
                                    </div>

                                </div>
                                <div className='dps-numbering-2'><span>4</span></div>
                            </div> */}
                        </div>

                    </div>
                </div>

            </section>

            {/* Success Stories Start */}
            <section className='dep-sec-bg'>
                <NewSuccessStories departmentId={4} />
            </section>
            {/* Success Stories End */}

            {/* <section>
                <HomeCounter />
            </section> */}

            <section className='dep-sec-bg'>
                {/* <NewDepartmentTeam /> */}
                <OurDepartmentTeam teamData={pageData.team} department={4} />
            </section>

            <section >
                <HomeNoticesDepartment department={4} />
            </section>

            {/* <section>
                <OurDepartmentTeam />
            </section> */}


            <section>
                <ContactUsDepartment department={5} />
            </section>

            {/* <section className="section-lgb other-dep-check-out-div">
                <div className="container pbmit-col-stretched-yes pbmit-col-right">

                    <div className="row main-div">
                        <h2 className='pbmit-service-title data-direction dd-mb'>Other Depatments To Checkout</h2>
                        <div className="col-12">

                            <div className="pbmit-col-stretched-right">

                                <div className="swiper-slider" data-autoplay="true" data-loop="true" data-dots="false" data-arrows="false" data-columns="4" data-margin="30" data-effect="slide">
                                    <div className="swiper-wrapper">
                                        <Swiper
                                            modules={[Navigation, Scrollbar, A11y, Autoplay]}
                                            spaceBetween={10}
                                            slidesPerView={4}
                                            

                                            loop={true}>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    

                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage1} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Renewable Management</Link>
                                                                        </h3>
                                                                        <div className="pbmit-service-category">
                                                                           
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>

                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage2} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Petroleum Management</Link>
                                                                        </h3>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage3} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Environment Management</Link>
                                                                        </h3>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    
                                                    <article className="pbmit-service-style-4">
                                                        <div className="pbminfotech-post-item">
                                                            <div className="pbmit-featured-wrapper img-border">
                                                                <img src={ServiceImage4} className="img-fluid img-radius" alt="" />
                                                            </div>
                                                            <div className="pbminfotech-box-content">
                                                                <div className="pbminfotech-box-content-inner">
                                                                    <div className="pbmit-service-wrap data-direction">
                                                                        <h3 className="pbmit-service-title">
                                                                            <Link className='department-other-deptocheck' to="services-details.html">Petroleum Research Management</Link>
                                                                        </h3>
                                                                        

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* </div> */}
            <section className="rs-service-grid-3 pt-70 pb-120">
                {/* <div className="heading-container">
                    <h4 id="title1" className="title-animation-about-us">Other Departments to Checkout</h4>
                    <div className=" div-for-hr-animation"><hr id="hr1" className="animate-hr-tag-about-us" /><hr id="hr2" className="animate-hr-tag-about-us" />
                    </div>
                </div> */}
                <OnScrollAnimation title1="odcem1" title2="odcem2" hr1="odcem3" hr2="odcem4" heading="Other Departments to Checkout" subheading="" />
                <div className='nod-container'>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage1} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={ntm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/new-technology-management/5/0">New Technology<br /> Management</Link></h4>
                                    {/* <p> Environment Management  </p> */}
                                    <Link to="/departments/new-technology-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage2} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={rm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/renewable-management/5/0">Renewable<br />  Management</Link></h4>
                                    {/* <p> Renewable Management </p> */}
                                    <Link to="/departments/renewable-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage3} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={pm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/petroleum-management/5/0">Petroleum<br />  Management</Link></h4>
                                    {/* <p>Petroleum Management </p> */}
                                    <Link to="/departments/petroleum-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="rs-service-2__item mt-50">
                                <div className="rs-thumb">
                                    <img src={ServiceImage5} alt="image" />
                                </div>
                                <div className="rs-content normal-text">
                                    <div className="rs-icon">
                                        <img src={prm} />
                                    </div>
                                    <h4 className="title normal-text"><Link to="/departments/petroleum-research-management/5/0">Petroleum Research <br /> Management</Link></h4>
                                    {/* <p>Petroleum  Management </p> */}
                                    <Link to="/departments/petroleum-research-management/5/0"><ArrowOutwardIcon /></Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default RenewableManagement;
